<script lang="ts">
import Header from '../cmps/header/Header.svelte'
import NavBar from '../cmps/navbar/NavBar.svelte'
import Banner from '../cmps/checkout/Banner.svelte'
import { underMaintenance, currAccount, loggedInUser } from '../store/stores'
import UnderMaintenance from '../cmps/common/UnderMaintenance.svelte'
import { _ } from 'svelte-i18n'
import { beaconService } from '../services/APIs/beacon.service'

let sessionKeys: string[]

function openBeacon() {
	if (window.Beacon) window.Beacon('close')
	if ($currAccount && $loggedInUser) {
		beaconService.navigateBeacon('/ask/')
		beaconService.identifyBeacon()
		beaconService.prefillBeacon(
			`${$currAccount.name} Website Maintenance`,
			`Hi, when will the DogBase Teams Web App be back up?\n--- \n Name: ${
				$loggedInUser.firstName + ' ' + $loggedInUser.lastName
			} \n Account: ${$currAccount.name}\n Dogs: ${$currAccount.dogIds.length}\n Users: ${
				$currAccount.userIds.length
			}\n Teams: ${$currAccount.teamIds.length}`
		)
		beaconService.openBeacon()
	}
}
</script>

{#if $underMaintenance.isUnderMaintenance}
	<section class="maintenance-container flex-column">
		<UnderMaintenance message={$underMaintenance.message} />
		<button
			on:click={() => openBeacon()}
			class="btn theme-btn send-message">
			{$_('send_us')}
		</button>
	</section>
{:else}
	<div class="main-container">
		<NavBar />
		<section class="container">
			<Banner sessionKeys={sessionKeys ? sessionKeys : null} />
			<main>
				<Header />
				<slot />
			</main>
		</section>
	</div>
{/if}

<style lang="scss">
.maintenance-container {
	align-items: center;
	.send-message {
		max-width: 250px;
	}
}
.main-container {
	display: grid;
	grid-template-columns: auto 1fr;
	height: 100%;

	.container {
		display: flex;
		flex-direction: column;
		padding: 0;
		margin: 0;
		position: relative;
		min-width: 100%;

		main {
			padding: 20px 28px;
			background-color: var(--clr-primary-bg);
			height: 100%;
		}
	}
}
</style>
