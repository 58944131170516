<script lang="ts">
    export let results: {[key: string]: number} = {}

    // add up all of the values and set the var total
    $: total = Object.values(results).reduce((a, b) => a + b, 0)

    // define color constants for the bar subsections in order to ensure consistency
    const colors = ['#263eb8', '#f39b17', '#161313', '#dae02d', '#3d9265', '#d65656', '2dbfe0']
    </script>

    <div class="results-bar">
        {#if results != undefined}
            <div class="bar">
                {#each Object.entries(results) as [key, value], index (key)}
                    <div
                        class="results-bar-section bar-{index + 1}"
                        style="background-color: {colors[index % colors.length]}; width: {(value / total) *
                            100}%"
                        title="{key}: {value}">
                        <p class="distribution-text">
                            {Math.round((value / total) * 100)}%
                        </p>
                    </div>
                {/each}
            </div>
            <section class="color-explanation">
                {#each Object.entries(results) as [key], index (key)}
                    <div class="explanation-container">
                        <span class="circle" style="background-color: {colors[index % colors.length]}"></span>
                        <p class="explanation">{key}</p>
                    </div>
                {/each}
            </section>
        {/if}
    </div>

    <style lang="scss">
   .explanation::first-letter {
		text-transform: uppercase;
	}
   .results-bar {
        display: flex;
        flex-direction: column;
        max-height: 50px;
        width: 300px; /* Set a width for the results bar */
        gap: 0.2rem;

        .bar {
            display: flex;
            gap: 2px;
        }

        .color-explanation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .explanation-container {
                display: flex;
                align-items: center;
                gap: 2px;
                .explanation {
                    font-size: 0.8rem;
                }

                .circle {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    display: block;
                }
            }
        }

        .results-bar-section {
            display: flex;
            justify-content: flex-start;
            border-radius: 4px;
        }

        .distribution-text {
            font-size: 11px;
            padding: 3px;
            font-family: Nunito;
            color: white;
            font-weight: bold;
            text-transform: capitalize;
            text-align: center;
            margin-left: 0.25rem;
        }
    }
    </style>
