<script lang="ts">
import { fade } from 'svelte/transition'
import { onMount } from 'svelte'
import { createEventDispatcher } from 'svelte'
import { loggedInUser } from '../../../store/stores'
import type { DogModel } from '../../../models/dog.model'
import SelectDog from '../../../cmps/trainings/training/SelectDog.svelte'
import { _ } from 'svelte-i18n'
import Icon from 'svelte-icons-pack'
import HiSolidX from 'svelte-icons-pack/hi/HiSolidX'
import TrainingTypesList from './TrainingTypesList.svelte'
import Modal from './AIRecap.svelte'
import { dogService } from '../../../services/core/dog.service'
import type { Timestamp } from 'firebase/firestore'
import { dogbaseAiService } from '../../../services/AI/dogbaseAi.service'

type TypeInfoDog = {
	id: string
	image: string
	name: string
}

type TypeInfo = {
	count: number
	latestDate: Timestamp
	type: string
	dog: TypeInfoDog
	trainingTypeId: string
	ids: string[]
	iconUrl: string
}

export let trainings: {
	typeInfo: TypeInfo[]
}

let trainingTypeInfo: TypeInfo | null = null
let data

let dogsForDisplay: { value: string; label: string; image: string }[] = []

let trainingsForDisplay: {
	typeInfo: TypeInfo[]
} = { typeInfo: [] }

let selectedDogId = ''

const dispatch = createEventDispatcher()

function close() {
	dispatch('close')
}

async function handleTrainingTypeSelected(event) {
	trainingTypeInfo = event.detail
	data = await dogbaseAiService.getTrainingRecap(
		trainingTypeInfo.trainingTypeId,
		trainingTypeInfo.dog.id
	)
}

function handleSelectedDogupdate() {
	// This function will be called whenever the value changes
	getTrainingsForDisplay()
}

onMount(async () => {
	const accountDogs = await dogService.query($loggedInUser.selectedAccount.id)
	dogsForDisplay = getDogsForDisplay(accountDogs)
})

function getDogsForDisplay(dogs: DogModel[]) {
	return dogs.map((dog) => ({ label: dog.name, value: dog.id, image: dog.image }))
}

// every training in trainings with the dog.id == to the selected dog should be added to trainingsFordisplauy
function getTrainingsForDisplay() {
	let trainingsForDisplayArray = trainings.typeInfo.filter(
		(training) => training.dog.id === selectedDogId
	)
	trainingsForDisplay = {
		typeInfo: trainingsForDisplayArray
	}
}
</script>

{#if data && trainingTypeInfo}
	<Modal
		on:close={close}
		{trainingTypeInfo}
		{data} />
{:else}
	<div
		class="ai-summary-modal"
		in:fade={{ duration: 250 }}
		out:fade={{ duration: 250 }}>
		<div class="modal-content">
			<div class="background">
				<h1 class="header">{$_('choose_dog_and_type')}</h1>
			</div>
			<div>
				<button
					class="close-button"
					on:click={close}>
					<Icon src={HiSolidX} />
				</button>
			</div>
			<div class="list-container">
				<button
					class="close-button-inner"
					on:click={close}>
					<Icon src={HiSolidX} />
				</button>
				<select
					bind:value={selectedDogId}
					on:change={handleSelectedDogupdate}>
					<option
						disabled
						selected
						value="">Select a dog</option>
					{#each dogsForDisplay as dog (dog.value)}
						<option value={dog.value}>{dog.label}</option>
					{/each}
				</select>
			</div>
			<div class="list-container">
				<TrainingTypesList
					on:trainingTypeSelected={handleTrainingTypeSelected}
					{trainingsForDisplay}
					{selectedDogId} />
			</div>
		</div>
	</div>
{/if}

<style lang="scss">
.ai-summary-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(
		--gradient-2,
		linear-gradient(47deg, rgba(38, 74, 89, 0.75) 0%, rgba(45, 115, 146, 0.75) 100%)
	);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	.modal-content {
		background: white; /* Semi-transparent grey */
		max-width: 950px;
		max-height: 95%;
		border-radius: 8px;
		overflow-y: auto;

		.background {
			background-color: #ffa470;
			min-height: 150px;
			max-height: 200px;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}

		.close-button {
			position: absolute;
			right: 10px;
			top: 10px;
			background: none;
			border: none;
			font-size: 1.5em;
			cursor: pointer;
			&:hover {
				filter: brightness(1.2);
			}
		}

		.header {
			color: white;
			font-size: 2rem;
			margin: 1rem 1rem 1rem 2rem;
		}

		.list-container {
			.close-button-inner {
				display: none;
			}

			padding: 2em;
			position: relative;
			z-index: 9999;
			select {
				width: 100%;
				max-height: 300px;
				padding: 10px;
				border-radius: 5px;
				border: 1px solid #ccc;
				appearance: none;
				background-color: #fff;
				font-size: 16px;
			}
		}
	}
}

@media screen and (max-height: 600px) {
	.background {
		display: none !important;
	}
}

@media screen and (max-width: 1100px) {
	.modal-content {
		margin: 2.5rem;
	}
	.modal-content {
		max-height: 95%;
	}
}

@media screen and (max-width: 768px) {
	.close-button-inner {
		display: block;
	}
	.modal-content {
		max-height: 95% !important;
	}

	.close-button-inner {
		display: block;
	}
}

@media screen and (max-width: 600px) {
	.modal-content {
		max-height: 95%;
		width: 95%;
	}
	h1 {
		font-size: 12px;
	}
}
</style>
