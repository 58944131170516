<script lang="ts">
import AddDog from '../cmps/common/quick-actions/AddDog.svelte'
import { onMount, afterUpdate } from 'svelte'
import CreateTeam from '../cmps/common/quick-actions/CreateTeam.svelte'
import InviteUser from '../cmps/common/quick-actions/InviteUser.svelte'
import AccountInfo from '../cmps/dashboard/account-info/AccountInfo.svelte'
import Messages from '../cmps/dashboard/Messages.svelte'
import RssNews from '../cmps/dashboard/RssNews.svelte'
import { currAccount } from '../store/stores'
import DashboardQuickInsights from '../cmps/dashboard/DashboardQuickInsights.svelte'
import { Col, Container, Row } from 'sveltestrap'
import GetHelp from '../cmps/common/quick-actions/GetHelp.svelte'
import AddTrainingSession from '../cmps/common/quick-actions/AddTrainingSession.svelte'
import RecentActivities from '../cmps/dashboard/RecentActivities.svelte'
import { activityService } from '../services/core/activity.service'
import * as Sentry from '@sentry/browser'
import Shepherd from 'shepherd.js'
import { _ } from 'svelte-i18n'
import { user } from '../store/stores'
import LangAndUnitSelect from '../cmps/common/modal/LangAndUnitSelect.svelte'
import { utilService } from '../utils/util.service'
import { loggedInUser } from '../store/stores'
import { measurementType, tourShown } from '../cmps/common/modal/measurementStore'
import { paddleService } from '../services/APIs/paddle.service'

let isLargeScreen = false
let isMobileScreen = true
let activities: any
let tour: any
let sessionKeys: any

let isSelectOpen: boolean

const hasVisited = sessionStorage.getItem('hasVisited')
	? sessionStorage.getItem('hasVisited')
	: false

onMount(async () => {
	try {
		//  Update paddle with user email for paddle retain
		if ($loggedInUser) {
			paddleService.updatePaddle($loggedInUser.email)
		}

		sessionKeys = sessionStorage.getItem('sessionKeys')
			? await JSON.parse(sessionStorage.getItem('sessionKeys'))
			: await utilService.getSessionKeys()

		if (!hasVisited) {
			sessionStorage.setItem('hasVisited', 'true')
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		activities = await activityService.queryAccountActivities($currAccount.id, 6)

		if ($user && isFirstLogin($user) && ($tourShown === 'false' || !tourShown)) {
			initializeTour()
			if (tour) tour.start()
		}

		if ($tourShown === 'false' || !tourShown) {
			localStorage.setItem('tourShown', 'true')
		}

		if (!localStorage.getItem('measurement-type')) {
			measurementType.set(localStorage.getItem('measurement-type'))

			if (!$measurementType) {
				setTimeout(() => {
					isSelectOpen = true
				}, 100)
			}
		}
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
})

function initializeTour() {
	tour = new Shepherd.Tour({
		useModalOverlay: true,
		defaultStepOptions: {
			classes: 'shadow-md bg-purple-dark',
			scrollTo: false,
			when: {
				show() {
					const currentStep = Shepherd.activeTour?.getCurrentStep()
					const currentStepElement = currentStep?.getElement()
					const footer = currentStepElement?.querySelector('.shepherd-footer')
					const progress = document.createElement('span')
					progress.className = 'shepherd-progress'
					progress.innerText = `${Shepherd.activeTour?.steps.indexOf(currentStep) + 1} of ${
						Shepherd.activeTour?.steps.length
					}`
					footer?.insertBefore(
						progress,
						currentStepElement.querySelector('.shepherd-button:last-child')
					)
				}
			}
		}
	})

	const stepsInfo = [
		{
			element: '.account-info',
			text: 'account_info',
			on: 'bottom-end'
		},
		{
			element: '.create-team',
			text: 'create_team',
			on: 'right'
		},
		{
			element: '.add-dog',
			text: 'add_dog',
			on: 'left'
		},
		{
			element: '.invite-user',
			text: 'invite_user',
			on: 'bottom'
		},
		{
			element: '.add-training',
			text: 'add_training',
			on: 'left'
		}
	]

	stepsInfo.forEach((step) => {
		tour.addStep({
			text: $_(`tour_description.${step.text}`),
			attachTo: {
				element: step.element,
				on: step.on
			},
			classes: 'step-design',
			buttons: [
				{
					text: $_('exit'),
					action: function () {
						tour.complete()
					},
					classes: 'exit-button'
				},
				{
					text: $_('next'),
					action: tour.next
				}
			]
		})
	})

	tour.addStep({
		text: $_(`tour_description.${'dashboard-quick-insights'}`),
		attachTo: {
			element: '.dashboard-quick-insights',
			on: 'top'
		},
		classes: 'step-design',
		buttons: [
			{
				text: $_('exit'),
				action: function () {
					tour.complete()
				},
				classes: 'exit-button'
			}
		]
	})
	localStorage.setItem('tourShown', 'true')
	tourShown.set('true')
}

function isFirstLogin(user: any) {
	const creationTime = user.metadata.creationTime
	const lastSignInTime = user.metadata.lastSignInTime

	if (creationTime === lastSignInTime) {
		// It's the user's first login.
		return true
	} else {
		return false
	}
}

window.addEventListener('beforeunload', () => {
	sessionStorage.clear()
})

const handleResize = () => {
	isLargeScreen = window.innerWidth >= 1800
	isMobileScreen = window.innerWidth <= 768
}

afterUpdate(async () => {
	try {
		activities = await activityService.queryAccountActivities($currAccount.id, 6)
		handleResize()
	} catch (err: unknown) {
		Sentry.captureException(err)
	}
})

$: {
	const tourReady =
		$user && isFirstLogin($user) && ($tourShown === 'false' || !$tourShown) && $measurementType
	if (tourReady) {
		initializeTour()
		if (tour) tour.start()
	}
}
</script>

<div class="dashboard">
	<Container fluid>
		<Row class="gy-4 row flex sp-sm">
			<Col
				xl="4"
				lg="12"
				class="flex-grow-1 pl-md-0 pr-md-0">
				<AccountInfo />
			</Col>
			<Col
				xl="2"
				lg="4"
				md="4"
				sm="4"
				class="quick-action w-auto pl-md-0 pr-md-0">
				<CreateTeam {sessionKeys} />
			</Col>
			<Col
				xl="1"
				lg="4"
				md="4"
				sm="4"
				class="quick-action w-auto pl-md-0 pr-md-0">
				<InviteUser {sessionKeys} />
			</Col>
			<Col
				xl="2"
				lg="4"
				md="4"
				sm="4"
				class="quick-action w-auto pl-md-0 pr-md-0">
				<AddDog {sessionKeys} />
			</Col>
			<Col
				xl="1"
				lg="4"
				md="4"
				sm="4"
				class="quick-action w-auto pl-md-0 pr-md-0">
				<AddTrainingSession {sessionKeys} />
			</Col>
			{#if isLargeScreen}
				<Col
					xl="1"
					lg="4"
					md="4"
					sm="4"
					class="quick-action get-help w-auto">
					<GetHelp />
				</Col>
			{/if}
			<Col
				md="12"
				class="mb-4 pl-md-0 pr-md-0">
				<DashboardQuickInsights />
			</Col>
		</Row>
		<Row>
			<Col
				md="12"
				class="mb-4 pl-md-0 pr-md-0">
				<RecentActivities {activities} />
			</Col>
		</Row>
		<Row class="gx-4">
			<div class="col-md-6 mb-4 pl-md-0 pr-md-0">
				<Messages />
			</div>
			<div class="col-md-6 mb-4 pr-md-0 pl-md-0">
				<RssNews />
			</div>
		</Row>
	</Container>
</div>
<LangAndUnitSelect isOpen={isSelectOpen} />

<style lang="scss">
:global(.shepherd-modal-overlay-container) {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(101, 101, 101, 0.301);
	opacity: 0.3;
	z-index: 999;
}

:global(.step-design) {
	background-color: #ffffff;
	color: #333;
	border-radius: 8px;
	padding: 10px 15px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	z-index: 999;
	min-width: 250px;
	max-width: 350px;
	word-wrap: break-word;
	white-space: normal;
	outline: none !important;
}

:global(.step-design .shepherd-arrow) {
	display: none;
}

:global(.step-design .shepherd-content h3.shepherd-title) {
	color: #333;
	font-size: 16px;
	margin-bottom: 5px;
	background-color: red;
}

:global(.step-design .shepherd-content .shepherd-text) {
	font-size: 14px;
	margin: 5px 5px 20px 5px;
}

:global(.shepherd-button) {
	align-self: flex-end;
	color: #fcfcfc;
	font-family: Nunito-Bold;
	padding: 8px 20px;
	border-radius: 8px;
	background: linear-gradient(46.62deg, #264a59 0%, #2d7392 93.64%);
	text-transform: capitalize;
	border: none;
	font-size: 12px;
}

:global(.shepherd-footer) {
	align-items: center;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	justify-content: space-between;
	font-size: 12px;

	:global(.shepherd-progress) {
		font-size: 13px;
	}
}

:global(.step-design .shepherd-buttons button:hover) {
	background-color: #0056b3;
}

:global(.step-design .shepherd-footer button:first-child) {
	background: none;
	background-color: white;
	padding: 7px 19px;
	color: #8f95b2;
	font-family: Nunito-Light;
	outline: 1px solid #8f95b2;

	&:hover {
		outline: 1px solid #2d7392;
		color: #2d7392;
	}
}

:global(.shepherd-tooltip) {
	max-width: 300px;
}

@media screen and (max-width: 768px) {
	:global(.pl-md-0) {
		padding-left: 0 !important;
	}

	:global(.pr-md-0) {
		padding-right: 0 !important;
	}
}

@media screen and (max-width: 576px) {
	:global(.sp-sm) {
		justify-content: space-between !important;
	}
}
</style>
