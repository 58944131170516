<script lang="ts">
import { _ } from 'svelte-i18n'
import { Lottie } from 'lottie-svelte'
import { beaconService } from '../../services/APIs/beacon.service'
import {  currAccount, loggedInUser,  } from '../../store/stores'

export let message: string


/**
 * Opens the Beacon chat widget
 */
 function openBeacon() {
	if (window.Beacon) window.Beacon('close')
	if ($currAccount && $loggedInUser) {
		beaconService.navigateBeacon('/ask/')
		beaconService.identifyBeacon()
		beaconService.prefillBeacon(
			`${$currAccount.name} Website Maintenance Question`,
			`Hi, when will the DogBase Teams Web App be back up?\n--- \n Name: ${
				$loggedInUser.firstName + ' ' + $loggedInUser.lastName
			} \n Account: ${$currAccount.name}\n Dogs: ${$currAccount.dogIds.length}\n Users: ${
				$currAccount.userIds.length
			}\n Teams: ${$currAccount.teamIds.length}`
		)
		beaconService.openBeacon()
	}
}

</script>

<div class="lottie-container flex-column align-center justify-center">
	<span class="lottie"><Lottie path="/assets/animations/under-maintenance-animation.json" /></span>
	<h1 class="message">{message}</h1>
	<br />
	<br />
	<button
		on:click={() => openBeacon()}
		class="btn theme-btn send-message">
		{$_('send_us')}
	</button>
</div>

<style lang="scss">
.lottie-container {
	width: 100%;
	// padding-top: 15em;
	// max-height: 600px;
	.lottie {
		min-width: 50%;
		max-width: 900px;
		overflow: hidden;
	}

	.message {
		font-size: 2rem;
		color: #3f5b91;
		margin-bottom: 1.5rem;
	}
	.send-message {
		max-width: 250px;
	}
}
</style>
