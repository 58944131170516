<script lang="ts">
import { onMount } from 'svelte'
import { currAccount, currUsers } from '../store/stores'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
import type { AccountUserModel } from '../models/user.model'
import UserList from '../cmps/users/UserList.svelte'
import { accountService } from '../services/core/account.service'
import NoUserAnimation from '../cmps/common/animation/NoUserAnimation.svelte'
import { _ } from 'svelte-i18n'
import UserInviteButton from '../cmps/users/user/UserInviteButton.svelte'
import { loggedInUser } from '../store/stores'
import { currAccountUsers } from '../store/stores'
import { Container } from 'sveltestrap'
import { utilService } from '../utils/util.service'

let accountUsersPromise: Promise<AccountUserModel[]>
let accountUsers: AccountUserModel[]

let isAllowed = false
let sessionKeys: any
let disabled = true

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

async function getAccountUsers() {
	accountUsersPromise = accountService.queryUsers($currAccount.userIds, $currAccount.id)
	accountUsers = await accountUsersPromise
}

async function checkIfAllowed() {
	accountUsersPromise = accountService.queryUsers($currAccount.userIds, $currAccount.id)
	accountUsers = await accountUsersPromise
	if (accountUsers)
		if (accountUsers.find((user) => user.id === $loggedInUser.id).role == 'member') {
			return false
		}
	return true
}


onMount(async () => {

	isAllowed = await checkIfAllowed()
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	currUsers.set(null)
	getAccountUsers()
	currAccountUsers.set(accountUsers)
})
</script>

<Container
	class="users mt-4 p-0"
	fluid>
	{#if !$currUsers && !accountUsers}
		<CmpLoader />
	{:else if $currAccount && $currAccount.userIds.length === 0}
		<NoUserAnimation />
	{:else}
		{#await accountUsersPromise}
			<CmpLoader />
		{:then}
			<div class="users">
				<div class="flex-column main-content">
					<header class="flex align-center space-between list-header">
						<h1 class="title">{$_('users')}</h1>
						{#if isAllowed}
							<UserInviteButton />
						{/if}
					</header>
					{#if $currAccount && $currAccount.userIds.length === 0}
						<NoUserAnimation />
					{:else}
						<div class="user-list-container">
							<UserList
								{accountUsers}
								{disabled} />
						</div>
					{/if}
				</div>
			</div>
		{/await}
	{/if}
</Container>

<style lang="scss">
.users {
	.main-content {
		gap: 20px;

		.list-header {
			.title {
				color: var(--clr-txt-dark-primary);
				font-size: 20px;
			}
		}

		.user-list-container {
			display: grid;
			margin-bottom: 20px;
		}
	}
}
</style>
