<script lang="ts">
import { onMount, onDestroy } from 'svelte'
import { trainingDB } from '../services/database/training.db'
import type { TrainingModel } from '../models/training.model'
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import TrainingList from '../cmps/trainings/TrainingList.svelte'
import { writable } from 'svelte/store'
import { currAccount, currTrainings, loggedInUser } from '../store/stores'
import LoadMoreBtn from '../cmps/common/LoadMoreBtn.svelte'
import { _ } from 'svelte-i18n'
import NoTrainingAnimation from '../cmps/common/animation/NoTrainingAnimation.svelte'
import { Link } from 'svelte-navigator'
import CmpLoader from '../cmps/common/loader/CmpLoader.svelte'
import * as Typesense from 'typesense'
import type { SearchTrainingModel } from '../models/training.model'
import type { Timestamp } from 'firebase/firestore'
import SearchBar from '../cmps/common/SearchBar.svelte'
import { Container } from 'sveltestrap'
import { utilService } from '../utils/util.service'
import AIRecap from '../cmps/common/modal/AIRecapTrainingTypeSelect.svelte'

let unsubscribe: Unsubscribe

let disabled = true
let isAiRecapShown = false
let allTrainings: TrainingModel[] = []

$: {
	if (sessionKeys)
		disabled = !(
			sessionKeys.subscriptionStatus == 'active' || sessionKeys.subscriptionStatus == 'trialing'
		)
}

onMount(async () => {
	currTrainings.set(null)
	sessionKeys = sessionStorage.getItem('sessionKeys')
		? await JSON.parse(sessionStorage.getItem('sessionKeys'))
		: await utilService.getSessionKeys()
	loadPage()
	// clear allTrainings array to avoid duplicates on re-render
	allTrainings = []
	getAllTrainings()
})

onDestroy(() => {
	if (unsubscribe && typeof unsubscribe === 'function') {
		unsubscribe()
	}
})

let itemsPerPage = 10
let itemsOnPage = 0
let trainingListLength = 10

let searchBarValue = ''
let sessionKeys: any
let hits: {
	type: string
	team: any
	trainingTime: number
	dog: any
	trainedBy: any
	id: string
	accountId: string
	iconUrl: string
	dateTime: Timestamp
	event: any
}[]
let placeholder = $_('search')

function submit(ev: Event) {
	ev.preventDefault()
	if (searchBarValue != '') {
		let client = new Typesense.Client({
			nodes: [
				{
					host: import.meta.env.VITE_APP_TYPESENSE_URL,
					port: 443,
					protocol: 'https'
				}
			],
			apiKey: sessionKeys.typeSenseKey,
			connectionTimeoutSeconds: 2
		})

		let search = {
			q: searchBarValue,
			query_by: 'type,dog,trainedBy.name,team.name',
			per_page: 100
		}

		client
			.collections('trainings')
			.documents()
			.search(search)
			.then(function (searchResults) {
				hits = searchResults.hits.map((hit) => {
					return {
						type: hit.document['type'],
						team: hit.document['team'],
						trainingTime: hit.document['trainingTime'],
						dog: hit.document['dog'],
						trainedBy: hit.document['trainedBy'],
						id: hit.document['id'],
						accountId: hit.document['accountId'],
						iconUrl: hit.document['iconUrl'],
						dateTime: hit.document['dateTime'],
						createdAt: hit.document['createdAt'],
						event: hit.document['event']
					}
				})
				trainingsForDisplay.set(hits)
			})
	} else {
		hits = null
		itemsPerPage = 10
		itemsOnPage = 0
		trainingListLength = 10
		loadPage()
	}
}

async function loadPage() {
	itemsOnPage += itemsPerPage
	const paginated = await trainingDB.loadPage($loggedInUser.selectedAccount.id, itemsOnPage)
	unsubscribe = onSnapshot(paginated, (querySnapshot) => {
		const trainings: TrainingModel[] = []
		querySnapshot.forEach((doc) => trainings.push(doc.data() as TrainingModel))
		currTrainings.set(trainings)
		trainingsForDisplay.set(trainings)
		trainingListLength = trainings.length
	})
}

async function getAllTrainings() {
	const paginated = await trainingDB.getAllTrainings($loggedInUser.selectedAccount.id)
	unsubscribe = onSnapshot(paginated, (querySnapshot) => {
		querySnapshot.forEach((doc) => allTrainings.push(doc.data() as TrainingModel))
	})
}

function removeDuplicatesAndCountTypes(array) {
	// Array to store type info for each type
	const typeInfo = []

	// Iterate through the array to update typeInfo and calculate total training minutes
	array.forEach((obj) => {
		const { id, type, dog, trainingTypeId, dateTime, iconUrl, firstDate, trainingTime, handler } =
			obj
		const existingIndex = typeInfo.findIndex((item) => item.type === type && item.dog.id === dog.id)

		if (existingIndex === -1) {
			// If type not found, add it to the array
			typeInfo.push({
				type,
				trainingTypeId,
				dog,
				count: 1, // Initialize count to 1 for a new entry
				latestDate: dateTime,
				iconUrl,
				firstDate: dateTime,
				handler,
				ids: [id],
				totalTrainingMinutes: trainingTime // Initialize totalTrainingMinutes with trainingTime
			})
		} else {
			// If type found, update latest date, and add id if necessary
			if (dateTime > typeInfo[existingIndex].latestDate) {
				typeInfo[existingIndex].latestDate = dateTime
			}
			if (dateTime < typeInfo[existingIndex].latestDate) {
				typeInfo[existingIndex].firstDate = dateTime
			}
			typeInfo[existingIndex].ids.push(id)

			// Update totalTrainingMinutes for the type
			typeInfo[existingIndex].totalTrainingMinutes += trainingTime

			// Increment count for the existing entry
			typeInfo[existingIndex].count++
		}
	})

	// Return the sorted unique array, type info array, and total training minutes
	return { typeInfo }
}

const trainingsForDisplay = writable<SearchTrainingModel[]>([])
</script>

{#if isAiRecapShown}
	<AIRecap
		on:close={() => (isAiRecapShown = false)}
		trainings={removeDuplicatesAndCountTypes(allTrainings)} />
{/if}
<Container
	class="training p-0"
	fluid>
	{#if !$currTrainings}
		<CmpLoader />
	{:else}
		<div class="trainings">
			{#if $currTrainings.length > 0}
				<div class="flex-column main-content mt-4">
					<header class="list-header flex-row space-between align-center">
						<h1 class="title">{$_('choose_training_sessions')}</h1>
						<section class="btn-container">
							<button
								class="btn theme-btn btn-add-team"
								on:click|stopPropagation={() => (isAiRecapShown = true)}>{$_('intelligent_recap')}</button>
							<Link
								to="types"
								state={{ pageName: $_('create_training') }}>
								<button class="btn theme-btn btn-add-team">{$_('add_training')}</button>
							</Link>
						</section>
					</header>
					<form
						action=""
						on:submit={submit}>
						<SearchBar
							bind:searchTerm={searchBarValue}
							{placeholder} />
					</form>
					<div class="training-list-container grid mb-3">
						<TrainingList
							trainings={$trainingsForDisplay}
							{disabled} />
					</div>
				</div>
				{#if !hits}
					<LoadMoreBtn
						loadFunc={loadPage}
						{itemsOnPage}
						listLength={trainingListLength} />
				{/if}
			{:else}
				<div class="flex-column align-center no-trainings-animation mt-5">
					<NoTrainingAnimation />
					{#if $currAccount.teamIds.length > 0 && $currAccount.dogIds.length > 0}
						{#if disabled}
							<button
								{disabled}
								class="btn theme-btn btn-add-team">{$_('add_training')}</button>
						{:else}
							<Link
								to="types"
								state={{ pageName: $_('create_training') }}>
								<button class="btn theme-btn btn-add-team">{$_('add_training')}</button>
							</Link>
						{/if}
					{:else if $currAccount.teamIds.length == 0}
						<h1 class="title">{$_('no_team_message')}</h1>
						<div class="button-container">
							{#if disabled}
								<button
									{disabled}
									class="btn theme-btn btn-add-team">{$_('add_team')}</button>
							{:else}
								<Link
									to="/teams/edit"
									state={{ pageName: 'Create Team' }}>
									<button
										{disabled}
										class="btn theme-btn btn-add-team">{$_('add_team')}</button>
								</Link>
							{/if}
						</div>
					{:else if $currAccount.dogIds.length == 0}
						<h1 class="title">{$_('no_dogs_message')}</h1>
						<div class="button-container">
							{#if disabled}
								<button
									{disabled}
									class="btn theme-btn btn-add-team text-nowrap">{$_('add_dog')}</button>
							{:else}
								<Link
									to="/dogs/add"
									state={{ pageName: 'Add dog' }}>
									<button
										{disabled}
										class="btn theme-btn btn-add-team text-nowrap">{$_('add_dog')}</button>
								</Link>
							{/if}
						</div>
					{/if}
				</div>
			{/if}
		</div>
	{/if}
</Container>

<style lang="scss">
.trainings {
	.main-content {
		gap: 20px;
		.list-header {
			gap: 8px;
			.title {
				color: var(--clr-txt-dark-primary);
				font-size: 20px;
			}
		}
		.no-trainings-animation {
			gap: 30px;
		}
	}
}
@media screen and (max-width: 768px) {
	.list-header {
		display: flex;
		flex-direction: column !important;
	}
	.btn-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 0.5rem;
	}
}
</style>
