import './app.postcss'
import './styles/style.scss'
import App from './App.svelte'
import './services/firebase/firebase'
import './i18n/i18n'
import * as Sentry from "@sentry/svelte";
import { underMaintenance } from './store/stores'
import { maintenanceService } from './services/core/maintenance.service'
import { paddleService } from './services/APIs/paddle.service'
import { beaconService } from './services/APIs/beacon.service'



// Sentry setup
const isSentryEnabled = import.meta.env.VITE_APP_SENTRY_ENABLED === 'true';
// Used for Sentry tracePropagationTargets, disable for now as backend is not ready
//const appUrl = import.meta.env.VITE_APP_DOGBASE_URL;
//const insightsUrl = import.meta.env.VITE_APP_INSIGHTS_URL;
//const aiUrl = import.meta.env.VITE_APP_AI_URL;
//const webAppUrl = import.meta.env.VITE_APP_API_URL;

// Sentry run in production and staging only
if (isSentryEnabled) {
// DSN is not working if put in other file, this does not have any security issues
const DNS =  import.meta.env.VITE_APP_SENTRY_DNS;
const replaysSessionSampleRate = 0.1;


Sentry.init({
  dsn: DNS,
  release: import.meta.env.VITE_APP_SENTRY_PROJECT +'-'+ import.meta.env.VITE_APP_VERSION,
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets: [], // Disable baggage headers for all requests, missing in the backend
    }),
    Sentry.replayCanvasIntegration(),
     Sentry.replayIntegration({
      networkDetailAllowUrls: ['https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen',
        'https://firebaselogging-pa.googleapis.com/v1/firelog/legacy', 'https://identitytoolkit.googleapis.com/v1'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});
}

/**
 * Asynchronously initializes the app by creating a new instance of the App class
 * and setting the underMaintenance state to the result of the maintenanceService.isUnderMaintenance()
 * function. If the app target element is not found, it logs an error and returns null.
 *
 * @return {Promise<App|null>} A promise that resolves to the initialized App instance or null if the app target element is not found.
 */
async function initApp() {
  const appTarget = document.getElementById('app');
  if (appTarget) {
    const app = new App({
      target: appTarget
    });



   // For under maintenance mode
    const maintenance = await maintenanceService.isUnderMaintenance()
    underMaintenance.set({
      isUnderMaintenance: (maintenance).on,
      message: (maintenance).message
    });

    // Setup paddle
    paddleService.setupPaddle();

    // Setup beacon
    beaconService.initBeacon();

    return app;
  } else {
    console.error('App target element not found');
    return null;
  }
}

export default await initApp();