<script lang="ts">
import Button, { Label } from '@smui/button'
import { onDestroy, onMount } from 'svelte'
import SvgDogBaseFull from '../cmps/common/SvgDogBaseFull.svelte'
import { authService } from '../services/core/auth.service'
import { Auth } from '../services/firebase/firebase'

/** Interval ID for checking email verification status */
let verifyInterval: ReturnType<typeof setInterval>

/** Boolean indicating if the user's email is verified */
let userVerified = Auth.currentUser?.emailVerified

/**
 * Lifecycle function that runs when the component is mounted.
 * Sets up an interval to check for email verification.
 */
onMount(() => {
	verifyInterval = setInterval(checkForVerification, 2000)
})

/**
 * Lifecycle function that runs when the component is destroyed.
 * Clears the email verification check interval.
 */
onDestroy(() => {
	clearInterval(verifyInterval)
})

/**
 * Checks if the user's email has been verified.
 * If verified, reloads the window to update the UI.
 */
const checkForVerification = () => {
	if (Auth.currentUser && !userVerified) {
		Auth.currentUser.reload().then(() => {
			userVerified = Auth.currentUser?.emailVerified
				? Auth.currentUser?.emailVerified
				: false || false
		})
	}
	if (Auth.currentUser && userVerified) {
		window.location.reload()
	}
}
</script>

<main class="verify-email">
	<div class="flex-column main-container">
		<div class="svg-container">
			<SvgDogBaseFull width="300" />
		</div>
		<div class="flex-column justify-center card">
			<header class="flex-column align-center header">
				<h1 class="title">Verify your Email</h1>
				<h4 class="sub-title">Please check your inbox for verification link</h4>
				<i>(if you don't an email from DogBase, please check your spam folder)</i>
			</header>
			<h2 class="email">{Auth.currentUser?.email}</h2>

			<div class="flex justify-center">
				<Button
					variant="raised"
					on:click={authService.sendEmailVerify}>
					<Label>Send again</Label>
				</Button>
			</div>

			<button
				class="flex justify-center logout"
				on:click={authService.logout}>
				<span class="link">Log out</span>
			</button>
		</div>
	</div>
</main>

<style lang="scss">
.verify-email {
	display: grid;
	width: 100%;
	height: 100vh;
	background-image: url('/assets/img/login-background.png');
	background-repeat: no-repeat;
	background-position: left max(600px, 100%) top;
	background-color: var(--clr-primary-bg);

	.main-container {
		background-color: white;
		z-index: 10;
		padding: 30px 60px;
		padding-top: 20px;
		box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		width: 460px;
		margin: 0 auto;
		place-self: center;
		gap: 30px;

		.svg-container {
			align-self: center;
			width: 300px;
			height: 100%;
		}
	}

	.card {
		gap: 25px;
		--mdc-theme-secondary: var(--clr-primary);

		.header {
			margin-bottom: 20px;
			text-align: center;
			gap: 5px;

			.title {
				color: var(--clr-primary);
			}

			.sub-title {
				color: var(--clr-txt-light-secondary);
				font-size: 14px;
				font-family: Nunito-Light;
				font-weight: 300;
				width: 50%;
			}
		}

		.email {
			color: var(--clr-primary);
			align-self: center;
			border: 1px solid lightgray;
			padding: 8px 12px;
			border-radius: 8px;
		}

		.logout {
			font-size: 14px;
			font-family: Nunito-Light;
		}
	}
}
</style>
