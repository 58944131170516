<script lang="ts">
import SvgDogBaseFull from '../cmps/common/SvgDogBaseFull.svelte'
import Button, { Label } from '@smui/button'
import { useNavigate, useLocation } from 'svelte-navigator'
import { fade } from 'svelte/transition'
import { authValidation, type LoginFields } from '../utils/validation/auth.validationLogin'
import { authService } from '../services/core/auth.service'
import { beforeUpdate } from 'svelte'
import { _ } from 'svelte-i18n'
import { Form, Input } from 'sveltestrap'
import UnderMaintenance from '../cmps/common/UnderMaintenance.svelte'
import { underMaintenance, loggedInUser, user } from '../store/stores'

const navigate = useNavigate()
const location = useLocation()

/** @typedef {'email' | 'password'} LoginField */
type LoginField = 'email' | 'password'

/**
 * @type {LoginFields}
 * Object containing login form fields
 */
const loginFields: LoginFields = {
	email: { name: 'Email', value: '', invalid: false, errorMessage: '' },
	password: { name: 'Password', value: '', invalid: false, errorMessage: '' }
}

/**
 * Object containing form values
 * @type {{ email: string; password: string }}
 */

$:formValues = {
	email: loginFields.email.value,
	password: loginFields.password.value
}

let navigating = false
let resolveNavigating: () => void

/**
 * Redirects to home page if user is already logged in
 */
beforeUpdate(() => {
if ($loggedInUser || $user) window.location.assign('/')
})

/**
 * Handles form submission
 * @param {Event} ev - The submit event
 */
async function handleSubmit(ev: Event) {
	ev.preventDefault()

	//TODO: Full validation for all inputs
	for (const loginField in loginFields) validateField(loginField as LoginField)
	if (!authValidation.fullValidation(loginFields)) return

	authService.login(formValues).then(navigateToLastPage)
}

/**
 * Navigates to the last visited page or home
 */
function navigateToLastPage() {
	const from = $location.state?.from
	navigateTo(from && from !== '/login' ? from : '/')
}

/**
 * Navigates to a specified route
 * @param {string} to - The route to navigate to
 */
function navigateTo(to: string): void {
	resolveNavigating = () => navigate(to, { replace: true })
	navigating = true
}

/**
 * Validates a specific form field
 * @param {LoginField} fieldName - The name of the field to validate
 */
function validateField(fieldName: LoginField) {
	loginFields[fieldName] = authValidation[fieldName](loginFields[fieldName])
}

/**
 * Handles focus event for form fields
 * @param {LoginField} fieldName - The name of the focused field
 */
function handleFocus(fieldName: LoginField) {
	loginFields[fieldName].invalid = false
	loginFields[fieldName].errorMessage = ''
}
</script>

{#if $underMaintenance && $underMaintenance.isUnderMaintenance}
	<section class="maintenance-container flex-column">
		<UnderMaintenance message={$underMaintenance.message} />
	</section>
{:else}
	<main class="login">
		<div class="flex modal-divider">
			<div class="flex-column align-center form-container">
				{#if !navigating}
					<div
						class="svg-container"
						transition:fade={{ delay: 250, duration: 1000 }}
						on:outroend={() => {
							if ($underMaintenance && !$underMaintenance.isUnderMaintenance) resolveNavigating()
						}}>
						<SvgDogBaseFull />
					</div>
				{/if}
				<form
					on:submit={handleSubmit}
					class="flex-column justify-center login-form">
					<Form>
						<header class="flex-column header">
							<h1 class="title">{$_('sign_in')}</h1>
							<h4 class="sub-title">{$_('lets_jump_right_back')}</h4>
						</header>
						<fieldset class="flex-column text-fields">
							<div class="flex-column field email">
								<Label for="email">{$_('email_address')}</Label>
								<Input
									type="email"
									name="email"
									id="email"
									class="rounded"
									placeholder="Your email"
									bind:value={loginFields.email.value}
									bind:invalid={loginFields.email.invalid}
									on:blur={() => validateField('email')}
									on:focus={() => handleFocus('email')}
									required={true} />
							</div>
							<div class="flex-column field password">
								<Label for="password">{$_('password')}</Label>
								<Input
									type="password"
									name="password"
									id="password"
									class="rounded"
									placeholder="Your password"
									bind:invalid={loginFields.password.invalid}
									bind:value={loginFields.password.value}
									on:blur={() => validateField('password')}
									on:focus={() => handleFocus('password')}
									required={true} />
							</div>
						</fieldset>
						<div class="flex-column align-center sign-in-btn">
							<Button
								variant="raised"
								on:click={handleSubmit}>
								<Label>{$_('sign_in')}</Label>
							</Button>
						</div>

						<div class="flex-column justify-center form-help">
							<div class="flex justify-center suggest-signup">
								{$_('no_account_message')}
								<a href="/signup">
									&nbsp;
									<span class="link">{$_('sign_up')}</span>
								</a>
							</div>
							<a href="/forgot">
								<span class="link forgot-password">{$_('forgot_password')}</span>
							</a>
						</div>
					</Form>
				</form>
			</div>
			<div class="right img-container">
				<img
					src="/assets/img/root-screen-image.jpg"
					alt="dog" />
			</div>
		</div>
	</main>
{/if}

<style lang="scss">
.login {
	display: grid;
	width: 100%;
	height: 100vh;
	background-image: url('/assets/img/login-background.png');
	background-repeat: no-repeat;
	background-position: left max(600px, 100%) top;
	background-color: var(--clr-primary-bg);
	background-size: contain;

	.modal-divider {
		z-index: 10;
		box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		width: fit-content;
		margin: 0 auto;
		place-self: center;

		.img-container {
			max-width: 300px;
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.form-container {
		background-color: white;
		padding: 60px;
		padding-top: 20px;
		gap: 25px;

		.svg-container {
			width: 300px;
			height: 100%;
		}

		.login-form {
			width: 100%;
			--mdc-theme-secondary: var(--clr-primary);

			:global(.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label) {
				color: var(--clr-primary);
			}

			.header {
				text-align: center;
				margin-bottom: 20px;
				:focus-visible {
					outline: none;
				}

				.title {
					color: var(--clr-primary);
				}
				.sub-title {
					color: var(--clr-txt-light-secondary);
					font-size: 14px;
					font-family: Nunito-Light;
					font-weight: 300;
				}
			}
			.text-fields {
				.field {
					margin-bottom: 20px;
				}
			}

			.sign-in-btn {
				margin-bottom: 10px;
			}

			.form-help {
				gap: 5px;
				font-size: 14px;
				text-align: center;
				.suggest-signup {
					font-family: Nunito-Light;
				}
				.forgot-password {
					font-size: 12px;
				}
			}
		}
	}
}

.maintenance-container {
	align-items: center;
}

@media (max-width: 768px) {
	.login .modal-divider .img-container {
		display: none;
	}

	.login .form-container {
		padding: 30px;

		.svg-container {
			width: 280px;
		}

		.login-form {
			gap: 20px;
		}
	}
}
</style>
