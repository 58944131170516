<script lang="ts">
import type { TrainingTypeCount } from '../../models/dog.model'

export let results: { [key: string]: TrainingTypeCount } | undefined

// add up all of the values and set the var total
$: total = results ? Object.values(results).reduce((acc, result) => acc + result.count, 0) : 0;

// define color constants for the bar subsections in order to ensure consistency
const colors = [
    '#263eb8', '#f39b17', '#161313', '#dae02d', '#3d9265', '#d65656', '#2dbfe0',
    '#8e44ad', '#2980b9', '#27ae60', '#e67e22', '#e74c3c', '#f1c40f', '#16a085',
    '#34495e', '#7f8c8d', '#c0392b', '#d35400', '#8e44ad', '#2c3e50', '#bdc3c7',
    '#95a5a6', '#7f8c8d', '#2ecc71', '#3498db', '#9b59b6', '#e74c3c', '#f39c12',
    '#d35400', '#c0392b'
];
</script>

<div class="results-bar">
	{#if results != undefined}
		<div class="bar">
			{#each Object.entries(results) as [key, value], index (key)}
				<div
					class="results-bar-section bar-{index + 1}"
										style="background-color: {colors[index % colors.length]}; width: {((value.count / total) * 100 -index+20).toFixed(0)}%"
					title="{value.name.replace('Public', '')}: {value.count}">
					<p class="distribution-text">
						{((value.count / total) * 100).toFixed(0)}%
					</p>
				</div>
			{/each}
		</div>
		<section class="color-explanation">
			{#each Object.entries(results) as [key, value], index (key)}
				<div class="explanation-container">
					<span
						class="circle"
						style="background-color: {colors[index % colors.length]}" />
					<p class="explanation">{value.name.replace('Public', '')} </p>
				</div>
			{/each}
		</section>
	{/if}
</div>

<style lang="scss">
.results-bar {
	display: flex;
	flex-direction: column;
	max-height: 50px;
	gap: 0.2rem;

	.bar {
		display: flex;
		gap: 2px;
	}

	.color-explanation {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap; // Allow wrapping to a new line

		.explanation-container {
			display: flex;
			align-items: center;
			flex: 1 1 auto; // Allow the container to grow and shrink
			gap: 4px;
			padding: 4px; // Add padding around each container
			.explanation {
				font-size: 0.8rem;
				white-space: normal; // Prevent text wrapping within the container
				padding-left: 4px; // Add padding to the left of the text
			}

			.circle {
				width: 5px;
				height: 5px;
				border-radius: 50%;
			}
		}
	}

	.results-bar-section {
		display: flex;
		justify-content: flex-start;
		border-radius: 4px;
		overflow: hidden; // Ensure the text does not overflow the container
	}

	.distribution-text {
		font-size: 11px;
		padding: 4px;
		font-family: Nunito;
		color: white;
		font-weight: bold;
		text-transform: capitalize;
		text-align: center;
		margin-left: 0.25rem;
		white-space: nowrap; // Prevent text wrapping
		overflow: hidden; // Hide overflow text
		text-overflow: ellipsis; // Add ellipsis for overflow text
	}
}
</style>
