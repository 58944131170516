import type { User } from 'firebase/auth'
import { writable } from 'svelte/store'
import type { AccountModel } from '../models/account.model'
import type { DogModel } from '../models/dog.model'
import type { TeamModel } from '../models/team.model'
import type { UserModel } from '../models/user.model'
import type { TrainingModel } from '../models/training.model'
import type { AccountUserModel } from '../models/user.model'
import { utilService } from '../utils/util.service'

/**
 * Writable store for the current Firebase user.
 * @type {import('svelte/store').Writable<User | null>}
 */
export const user = writable<User | null >(null);

/**
 * Writable store for the logged-in user model.
 * @type {import('svelte/store').Writable<UserModel | null>}
 */
export const loggedInUser = writable<UserModel | null>(null);

/**
 * Writable store for the current account model.
 * @type {import('svelte/store').Writable<AccountModel | null>}
 */
export const currAccount = writable<AccountModel | null>(null);

/**
 * Writable store for the current teams.
 * @type {import('svelte/store').Writable<TeamModel[] | undefined>}
 */
export const currTeams = writable<TeamModel[] | undefined>(undefined);

/**
 * Writable store for the current dogs.
 * @type {import('svelte/store').Writable<DogModel[] | undefined>}
 */
export const currDogs = writable<DogModel[] | undefined>(undefined);

/**
 * Writable store for the current trainings.
 * @type {import('svelte/store').Writable<TrainingModel[] | undefined>}
 */
export const currTrainings = writable<TrainingModel[] | undefined>(undefined);

/**
 * Writable store for the navigation bar state (open/closed).
 * @type {import('svelte/store').Writable<boolean>}
 */
export const navBarOpen = writable<boolean>(utilService.isClientInMobile() ? false : true)

/**
 * Writable store for the current users.
 * @type {import('svelte/store').Writable<UserModel[] | null>}
 */
// TODO: this needs to be removed, but it messed up the private route guard redirect
// Only used in Users.svelte, also there is currUser which is used in AuthService, UserService etc
export const currUsers = writable<UserModel[] | null>(null);

/**
 * Writable store for the selected plan.
 * @type {import('svelte/store').Writable<string | undefined>}
 */
export const selectedPlan = writable<string | undefined>(undefined);

/**
 * Writable store for the current account users.
 * @type {import('svelte/store').Writable<AccountUserModel[] | undefined>}
 */
export const currAccountUsers = writable<AccountUserModel[] | undefined>(undefined);

/**
 * Writable store for event data.
 * TODO: This needs to be renamed to paddleEventData and proper type.
 * @type {import('svelte/store').Writable<Record<string, unknown>>}
 */
//TODO: this needs to be renamed to paddleEventData and proper type
export const eventData = writable<Record<string, unknown>>({});

/**
 * Writable store for the selected AI training model.
 * @type {import('svelte/store').Writable<TrainingModel | null>}
 */
export const selectedAITraining = writable<TrainingModel | null>(null);

/**
 * Writable store for the maintenance status.
 * @type {import('svelte/store').Writable<MaintenanceStatus>}
 */
export const underMaintenance = writable<MaintenanceStatus>({ isUnderMaintenance: false, message: '' });


