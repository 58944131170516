<script lang="ts">
import { onDestroy, onMount } from 'svelte'
import { eventData } from '../../store/stores'
import { currAccount } from '../../store/stores'
import { loggedInUser } from '../../store/stores'
import { utilService } from '../../utils/util.service'
import type {
	PaddleCheckoutSettings,
	PaddleCheckoutItem,
	PaddleCheckoutOptions
} from '../../types/paddle'
import { _ } from 'svelte-i18n'
import { userMsg } from '../../utils/userMsg.service'
import * as Sentry from '@sentry/browser'
import { error } from 'jquery'
import CmpLoader from '../common/loader/CmpLoader.svelte'



export let selectedPlan: string
let countryCode: string
let pricesError = false

onMount(async () => {
	if ($currAccount?.location) countryCode = await getCountryCode()
	openCheckout(selectedPlan)
})

/**
 * Asynchronously fetches the country code of the current user's location.
 * @returns {Promise<string>} The country code of the user's location, or an empty string if the location or geoPoints property of the user's account is undefined.
 * @throws {Error} If the location or geoPoints property of the user's account is undefined.
 */
//TODO: should be in a service
const accessToken = import.meta.env?.VITE_APP_MAPBOX_ACCESS_TOKEN
async function getCountryCode() {
	const location = $currAccount?.location
	if (location && location.geoPoints) {
		const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.geoPoints.latitude},${location.geoPoints.longitude}.json?access_token=${accessToken}`
		const locationData = await utilService.restRequest(url, 'GET')
		return locationData.features ? locationData.features.slice(-1)[0].properties.short_code : ''
	} else {
		// Handle the case when location or geoPoints is undefined
		userMsg.errorMsg('Error: Location or geoPoints is undefined.')
		console.warn('Location or geoPoints is undefined')
		Sentry.captureException(error)
		return ''
	}
}

/**
 * Closes the Paddle Checkout if it is available.
 * Shows an error message and logs a warning if it is not available.
 */
onDestroy(() => {
	if (window.Paddle && window.Paddle.Checkout) {
		window.Paddle.Checkout.close()
	} else {
		userMsg.errorMsg('Paddle Checkout is not available.')
		console.warn('Paddle Checkout is not available.')
	}
})

/**
 * Opens the Paddle Checkout with the specified product ID.
 *
 * @param {string} productId - The ID of the product to be purchased.
 */
function openCheckout(productId: string) {
	const email = $loggedInUser?.email
	const language = localStorage.getItem('language')
	const locale = language === 'he' ? 'en' : language

	//TODO: Need to move to paddle service
	const commonSettings: PaddleCheckoutSettings = {
		displayMode: 'inline',
		theme: 'light',
		locale,
		frameTarget: 'checkout-container',
		frameInitialHeight: '450',
		frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;'
	}


	const items: PaddleCheckoutItem[] = [
		{
			priceId: productId,
			quantity: $currAccount?.dogIds.length
		}
	]

	const options: PaddleCheckoutOptions = {
		settings: commonSettings,
		items
	}

	if (email) {
		options.customer = {
			email,
			address: {
				countryCode: countryCode ? countryCode.toUpperCase() : ''
			}
		}
	}

	if (window.Paddle && window.Paddle.Checkout) {
		window.Paddle.Checkout.open(options)
	} else {
		pricesError = true
		console.warn('Paddle Checkout is not available.')
	}
}


</script>

<div class="payment flex">
	<div class="product-info-container flex-column align-center">
		{#if $eventData && Array.isArray($eventData.items) && $eventData.items.length > 0}
			<div class="order-summary flex-column">
				<div class="order-summary flex-column">
					<div class="header flex">
						<img
							class="product-image"
							src={$eventData?.items[0].product.image_url}
							width="75px"
							height="75px"
							alt="product" />
						<h1 class="total-title">
							{$eventData.recurring_totals.total}
							{$eventData.currency_code}
						</h1>
					</div>
					<div class="table-container">
						<table>
							<tbody>
								{#if $eventData}
									{#each $eventData.items as { product, totals } ($eventData.items.id)}
										<tr class="flex">
											<td class="product-name">{product.name}</td>
											<td>{totals.subtotal.toFixed(2)} {$eventData.currency_code}</td>
										</tr>
									{/each}
								{/if}
							</tbody>
						</table>
					</div>

					<div class="to-pay-today flex-column gap-1 mt-1 mb-1">
						<section class="pay-header flex">
							<h2>{$_('to_pay_today')}</h2>
							<p class="quantity">Qty: {$eventData.items[0].quantity}</p>
						</section>

						<div class="flex">
							{$_('subtotal')}
							<span id="subtotal">
								{$eventData.totals.subtotal}
								{$eventData.currency_code}
							</span>
						</div>
						<div class="flex">
							{$_('tax')} <span id="tax">{$eventData.totals.tax} {$eventData.currency_code}</span>
						</div>
						<div class="flex">
							{$_('due_today')}
							<span id="total">{$eventData.totals.total} {$eventData.currency_code}</span>
						</div>
					</div>

					<div class="to-pay-each-period flex-column gap-1">
						<section class="pay-header flex">
							<h2>
								{$_('to_pay_each')} <span id="recurring-interval">{$_('period')} </span>
							</h2>
							<p class="quantity">Qty: {$eventData.items[0].quantity}</p>
						</section>

						<div class="flex">
							{$_('subtotal')}
							<span id="recurring-subtotal"
								>{$eventData.recurring_totals.subtotal}
								{$eventData.currency_code}</span>
						</div>
						<div class="flex">
							{$_('tax')}
							<span id="recurring-tax"
								>{$eventData.recurring_totals.tax} {$eventData.currency_code}</span>
						</div>
						<div class="flex">
							{$_('due_today')}
							<span id="recurring-total">
								{$eventData.recurring_totals.total}
								{$eventData.currency_code}
							</span>
						</div>
					</div>
				</div>
			</div>
		{:else}
			<div class="order-summary flex-column">
				<CmpLoader />
			</div>
		{/if}
		{#if pricesError}
			<div class="error-message">
				{$_('error_order_summary')}
			</div>
		{/if}
	</div>
	<div class="checkout">
		<div class="checkout-container" />
	</div>
</div>

<style lang="scss">
@use '../../styles/setup/mixins';

.error-message {
	text-align: center;
	vertical-align: middle;
}

.product-info-container {
	max-width: 50%;
}

.payment {
	@include mixins.card;
	justify-content: center;
	padding: 28px 24px;
	width: 100%;
	min-height: 680px;
	gap: 70px;

	.checkout {
		width: 100%;
	}
}

.product-info-container {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	max-width: 500px;
	background-color: rgba(0, 0, 0, 0.03);
	border-radius: 6px;
	padding: 72px 32px;

	.order-summary {
		width: 70%;

		.header {
			gap: 24px;

			min-width: 400px;
			max-height: 75px;
			.product-image {
				object-fit: cover;
				border-radius: 50%;
			}
			.total-title {
				font-size: 2.5rem;
			}
		}

		.table-container {
			overflow-x: auto;

			table {
				width: 100%;
				border-collapse: collapse;
				margin-bottom: 20px;

				tr {
					justify-content: space-between;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}

				td {
					font-size: 17px;
					padding: 12px 0;
				}
			}
		}

		.to-pay-today,
		.to-pay-each-period {
			padding: 20px 0;

			.pay-header {
				align-items: center;
				justify-content: space-between;
				margin-bottom: 8px;
			}
			div {
				width: 100%;
				justify-content: space-between;
				font-size: 15px;

				#total,
				#recurring-total {
					font-family: Nunito-Bold;
				}
			}
		}

		.to-pay-today {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}

		.total-title {
			font-size: 52px;
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 900px) {
	.payment {
		flex-direction: column;
		gap: 40px;
	}
	.product-info-container {
		padding: 60px 0;
		width: 100%;

		.total-title {
			margin: 15px 0;
		}
	}

	:global() {
		.table-container {
			td {
				white-space: nowrap;
			}

			.product-name {
				white-space: unset;
			}
		}
	}
}
</style>
