import { writable } from 'svelte/store';

//TODO: Need to set the proper type for each store
export const toggleSwitchSlider = writable<Record<string, unknown>>({});
export const toggleSwitchText = writable<Record<string, unknown>>({});
export const toggleTextToTags = writable<Record<string, unknown>>({});
export const toggleSwitchStepper = writable<Record<string, unknown>>({});
export const toggleDistanceSlider = writable<Record<string, unknown>>({});
export const toggleMultiText = writable<Record<string, unknown>>({});
export const toggleSwitchDropdown = writable<Record<string, unknown>>({});
export const toggleFitness = writable<Record<string, unknown>>({});
export const toggleMultiSelect = writable<Record<string, unknown>>({});
export const dropdownTextToTags = writable<Record<string, unknown>>({});
export const dropdownText = writable<Record<string, unknown>>({});
export const dropdownMultiSlider = writable<Record<string, unknown>>({});
export const dropdownMultiSliderSliderValues = writable<Record<string, unknown>>({});
export const dropdownMultiSliderTextFieldValues = writable<Record<string, unknown>>({});
export const multiSelectSlider = writable<Record<string, unknown>>({});
export const weightSlider = writable<Record<string, unknown>>({});
export const timerSlider = writable<Record<string, unknown>>({});
export const rangeSlider = writable<Record<string, unknown>>({});
export const customStepper = writable<Record<string, unknown>>({});
export const distanceStepper = writable<Record<string, unknown>>({});
export const daysToYears = writable<Record<string, unknown>>({});
export const bitLossECorrection = writable<Record<string, unknown>>({});
export const stepperMultiply = writable<Record<string, unknown>>({});
export const stepperMultiplyHelper = writable<Record<string, unknown>>({});
export const multiselectTextToTags = writable<Record<string, unknown>>({});
export const toggleMultiSlider = writable<Record<string, unknown>>({});
export const dropdownBottomSheet = writable<Record<string, unknown>>({});
export const textArea = writable<Record<string, unknown>>({});
export const textToTags = writable<Record<string, unknown>>({});
export const multiselect = writable<Record<string, unknown>>({});
export const toggle = writable<Record<string, unknown>>({});
export const distanceSlider = writable<Record<string, unknown>>({});

export function clearAllStores() {
  toggleSwitchSlider.set({});
  toggleSwitchText.set({});
  toggleTextToTags.set({});
  toggleSwitchStepper.set({})
  toggleDistanceSlider.set({})
  toggleMultiText.set({})
  toggleSwitchDropdown.set({})
  toggleFitness.set({})
  dropdownTextToTags.set({})
  dropdownText.set({})
  dropdownMultiSlider.set({})
  dropdownMultiSliderSliderValues.set({})
  dropdownMultiSliderTextFieldValues.set({})
  multiSelectSlider.set({})
  weightSlider.set({})
  timerSlider.set({})
  rangeSlider.set({})
  customStepper.set({})
  distanceStepper.set({})
  bitLossECorrection.set({})
  daysToYears.set({})
  stepperMultiply.set({})
  toggleMultiSelect.set({})
  multiselectTextToTags.set({})
  toggleMultiSlider.set({})
  stepperMultiplyHelper.set({})
  dropdownBottomSheet.set({})
  textArea.set({})
  textToTags.set({})
  multiselect.set({})
  toggle.set({})
  distanceSlider.set({})
}