<script lang="ts">
import Button, { Label } from '@smui/button'
import { fade } from 'svelte/transition'
import { useNavigate } from 'svelte-navigator'
import SvgDogBaseFull from '../cmps/common/SvgDogBaseFull.svelte'
import { type AuthField, authValidation } from '../utils/validation/auth.validation'
import HelperText from '@smui/textfield/helper-text'
import { authService } from '../services/core/auth.service'
import { loggedInUser } from '../store/stores'
import { beforeUpdate } from 'svelte'
import { _ } from 'svelte-i18n'
import { Form, Input } from 'sveltestrap'

const navigate = useNavigate()

/**
 * @typedef {Object} AuthField
 * @property {string} value - The value of the field.
 * @property {string} name - The name of the field.
 * @property {boolean} invalid - Indicates if the field is invalid.
 * @property {string} errorMessage - The error message for the field.
 */

/** @type {AuthField} */
let email: AuthField = {
	value: '',
	name: 'email',
	invalid: false,
	errorMessage: ''
}

let navigating = false
let resolveNavigating: () => void


/**
 * Redirects to the home page if the user is logged in.
 */
beforeUpdate(() => {
	if ($loggedInUser) window.location.assign('/')
})

/**
 * Validates the email field.
 */
function validateField() {
	email = authValidation.email(email)
}

/**
 * Resets the invalid state and error message of the email field.
 */
function handleFocus() {
	email.invalid = false
	email.errorMessage = ''
}

/**
 * Handles the form submission to reset the password.
 * @param {Event} ev - The form submission event.
 */
function handleSubmit(ev: Event) {
	ev.preventDefault()
	authService.resetPassword(email.value)
	navigateTo('/login')
}

/**
 * Navigates to the specified path.
 * @param {string} to - The path to navigate to.
 */
function navigateTo(to: string): void {
	resolveNavigating = () => navigate(to, { replace: true })
	navigating = true
}
</script>

<main class="forgot">
	<div class="flex-column form-container">
		{#if !navigating}
			<div
				class="svg-container"
				transition:fade={{ delay: 250, duration: 1000 }}
				on:outroend={() => resolveNavigating()}>
				<SvgDogBaseFull width="300" />
			</div>
		{/if}
		<Form
			on:submit={handleSubmit}
			class="flex-column justify-center forgot-form">
			<header class="flex-column align-center header">
				<h1 class="title">{$_('forgot_your_password')}</h1>
				<h4 class="sub-title">{$_('confirm_email')}</h4>
			</header>
			<Label for="email">{$_('email_address')}</Label>
			<Input
				class="forgot-email-input rounded"
				type="email"
				placeholder="Your email*"
				required={true}
				bind:value={email.value}
				bind:invalid={email.invalid}
				on:blur={validateField}
				on:focus={handleFocus}
				autocomplete="email">
				<HelperText
					validationMsg>
					{email.errorMessage}
				</HelperText>
			</Input>
			<div class="flex justify-center submit-btn">
				<Button
					variant="raised"
					on:click={handleSubmit}>
					<Label>{$_('submit')}</Label>
				</Button>
			</div>
			<div class="flex justify-center suggest-sign-in">
				{$_('take_me_back_to')}
				<a href="/login">
					&nbsp;
					<span class="link">{$_('sign_in')}</span>
				</a>
			</div>
		</Form>
	</div>
</main>

<style lang="scss">
.forgot {
	display: grid;
	width: 100%;
	height: 100vh;
	background-image: url('/assets/img/login-background.png');
	background-repeat: no-repeat;
	background-position: left max(600px, 100%) top;
	background-color: var(--clr-primary-bg);

	.form-container {
		background-color: white;
		z-index: 10;
		padding: 30px 60px;
		padding-top: 20px;
		box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
		border-radius: 16px;
		overflow: hidden;
		width: 460px;
		margin: 0 auto;
		place-self: center;
		gap: 30px;

		.svg-container {
			align-self: center;
			width: 300px;
			height: 80px;
		}

		:global(.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label) {
			color: var(--clr-primary);
		}

		.submit-btn {
			margin: 3rem 0 1rem 0;
		}

		.header {
			margin-bottom: 20px;
			text-align: center;
			gap: 5px;

			.title {
				color: var(--clr-primary);
			}

			.sub-title {
				color: var(--clr-txt-light-secondary);
				font-size: 14px;
				font-family: Nunito-Light;
				font-weight: 300;
				width: 50%;
			}
		}
		.suggest-sign-in {
			font-size: 14px;
			font-family: Nunito-Light;
		}
	}
}

@media (max-width: 768px) {
	.forgot .form-container {
		padding: 30px 40px;
		width: 85%;

		.svg-container {
			width: 280px;
		}

		.header .sub-title {
			font-size: 14px;
			width: 70%;
		}
	}
}
</style>
