<script lang="ts">
import Icon from 'svelte-icons-pack'
import  BsArrowLeftCircleFill  from "svelte-icons-pack/bs/BsArrowLeftCircleFill";
import  BsArrowRightCircleFill  from "svelte-icons-pack/bs/BsArrowRightCircleFill";
import type { TrainingTypeOverview } from '../../models/dog.model'

export let trainingTypeOverviews: TrainingTypeOverview[] | undefined
export let selectedTrainingTypeOverview: TrainingTypeOverview | undefined = undefined

let container: HTMLDivElement

function scrollLeft() {
	container.scrollBy({ left: -200, behavior: 'smooth' })
}

function scrollRight() {
	container.scrollBy({ left: 200, behavior: 'smooth' })
}

function handleClick(trainingTypeName: string) {
	selectedTrainingTypeOverview = trainingTypeOverviews?.find(
		(overview) => overview.trainingTypeName === trainingTypeName
	)
}

// on mount, select the first training type overview
$: if (trainingTypeOverviews != undefined) {
	selectedTrainingTypeOverview = trainingTypeOverviews[0]
}
// only show arrow buttons if there is more than 8 training types
$: showArrows = (trainingTypeOverviews?.length ?? 0) > 8
</script>

{#if trainingTypeOverviews != undefined}
	<div class="training-type-overview-wrapper">
		{#if showArrows}
		<button
			class="arrow left"
			on:click={scrollLeft}
			on:keydown={scrollLeft}
			on:keyup={scrollLeft}
			on:keypress={scrollLeft}><Icon src={BsArrowLeftCircleFill} /></button>
			{/if}
		<div
			class="training-type-overview"
			bind:this={container}>
			{#each Object.entries(trainingTypeOverviews) as [key, value], key (key)}
				<div class="training-type-overview-section">
					<div
						class="svg-container {selectedTrainingTypeOverview?.trainingTypeName.replace(
							'Public',
							''
						) === value.trainingTypeName.replace('Public', '')
							? 'selected'
							: ''}"
						on:click={() => handleClick(value.trainingTypeName)}
						on:keydown={() => handleClick(value.trainingTypeName)}
						on:keyup={() => handleClick(value.trainingTypeName)}
						on:keypress={() => handleClick(value.trainingTypeName)}>
						<img
							width="48px"
							height="48px"
							src={value?.trainingTypeIcon}
							alt="training type" />
					</div>
					<p class="training-type-name">{value.trainingTypeName.replace('Public', '')}</p>
				</div>
			{/each}
		</div>
		{#if showArrows}
			<button
				class="arrow right"
				on:click={scrollRight}
				on:keydown={scrollRight}
				on:keyup={scrollRight}
				on:keypress={scrollRight}><Icon src={BsArrowRightCircleFill} /></button>
		{/if}
	</div>
{/if}

<style lang="scss">
.training-type-overview-wrapper {
	display: flex;
	overflow: hidden;
	position: relative;
}

.training-type-overview {
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	flex-grow: 1;
	padding-left: 25px;
	padding-right: 25px;
	margin-top: 25px;
}

.training-type-overview-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 10px;
	cursor: pointer;
	.training-type-name {
		font-size: 12px;
		white-space: nowrap; // Prevent text wrapping
	}
}

.svg-container {
	width: 42px;
	height: 42px;
	margin: 0 10px;
	border-radius: 50%;
	background-color: #e0eaff;
	display: inline-block;
}

.svg-container:hover {
	background-color: #28586c;
}

.svg-container.selected {
	background-color: #28586c;
}

.arrow {
	background: none;
	border: none;
	font-size: 2rem;
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.arrow.left {
	left: 0;
}

.arrow.right {
	right: 0;
}
</style>
