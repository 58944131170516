<script lang="ts">
export let name: string
export let theme: 'primary' | 'secondary' = 'secondary'
export let color = null
export let fontSize = 20
export let alt = ''
</script>

<div
	class="name-initials {theme}"
	style:font-size="{fontSize}px"
	style:color>
	{#if name}
		{name
			.split(' ')
			.slice(0, 2)
			.map((n) => n.slice(0, 1))
			.join('')}
	{:else}
		{alt}
	{/if}
</div>

<style lang="scss">
@use '../../styles/setup/variables';
.name-initials {
	display: grid;
	place-items: center;
	object-fit: cover;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	color: var(--clr-secondary);
	background-color: var(--clr-primary);
	font-family: Nunito-Bold;
	font-weight: 600;
	text-transform: uppercase;

	&.primary {
		background-color: var(--clr-primary);
		color: var(--clr-secondary);
	}

	&.primary-white {
		background-color: var(--clr-primary);
		color: white;
	}

	&.secondary {
		background-color: lighten($color: variables.$clr-secondary, $amount: 35%);
		color: var(--clr-secondary);
	}

	&.secondary-white {
		background-color: var(--clr-secondary);
		color: white;
	}
}
</style>
