import { getAuth } from 'firebase/auth'

const AiUrl = import.meta.env.VITE_APP_AI_URL;

export const dogbaseAiService = {
	getTrainingRecap,
	getDogAIBooklet
}

/**
 * Fetches a training recap for a specific dog and training type.
 *
 * @async
 * @param {string} trainingTypeId - The ID of the training type.
 * @param {string} dogId - The ID of the dog.
 * @returns {Promise<any>} A promise that resolves to the training recap data.
 * If the current user is not authenticated, the promise resolves to null.
 * @throws {Error} Throws an error if the fetch request fails.
 */
async function getTrainingRecap(trainingTypeId: string, dogId: string) {
	const auth = getAuth()
	if (auth.currentUser) {
		const result = await auth.currentUser
			.getIdToken(/* forceRefresh */ true)
			.then(async function (idToken) {
				const myHeaders = new Headers()
				myHeaders.append('Content-Type', 'application/json')
				myHeaders.append('Authorization', `Bearer ${idToken}`)
				const response = await fetch(`${AiUrl}/api/v1/synchronous/summary/training/selectedTrainings?trainingTypeId=${trainingTypeId}&dogId=${dogId}`, {
					method: 'GET',
					headers: myHeaders
				})
				const values = await response.json()
				return values
			})
		return await result
	} else {
		return null
	}
}

/**
 * Fetches the AI booklet for a specific dog.
 *
 * @async
 * @param {string} dogId - The ID of the dog.
 * @returns {Promise<any>} A promise that resolves to the AI booklet data.
 * If the current user is not authenticated, the promise resolves to null.
 * @throws {Error} Throws an error if the fetch request fails.
 */
async function getDogAIBooklet(dogId: string) {
	const auth = getAuth()
	if (auth.currentUser) {
		const result = await auth.currentUser
			.getIdToken(/* forceRefresh */ true)
			.then(async function (idToken) {
				const myHeaders = new Headers()
				myHeaders.append('Content-Type', 'application/json')
				myHeaders.append('Authorization', `Bearer ${idToken}`)
				console.log(`${AiUrl}api/v1/synchronous/summary/dog/aibooklet?dogId=${dogId}`)
				const response = await fetch(`${AiUrl}/api/v1/synchronous/summary/dog/aibooklet?dogId=${dogId}`, {
					method: 'GET',
					headers: myHeaders
				})
				const values = await response.json()
				return values
			})
		return await result
	} else {
		return null
	}
}