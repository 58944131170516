<script lang="ts">
import UnderDevelopment from '../cmps/common/UnderDevelopment.svelte'


</script>

<div class="calendar">
	<UnderDevelopment />
</div>

<style lang="scss">
.calendar {
	height: 100vh;
}
</style>
