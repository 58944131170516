import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { firebaseConfig } from './firebase.config'
import { getPerformance } from "firebase/performance";

/**
 * Initializes the Firebase application with the provided configuration.
 * @type {firebase.app.App}
 */
const FirebaseApp = initializeApp(firebaseConfig)

/**
 * Gets the Firebase Auth instance for the initialized app.
 * @type {firebase.auth.Auth}
 */
const Auth = getAuth(FirebaseApp)

/**
 * Gets the Firestore instance for the initialized app.
 * @type {firebase.firestore.Firestore}
 */
const Firestore = getFirestore(FirebaseApp)

/**
 * Gets the Performance Monitoring instance for the initialized app.
 * @type {firebase.performance.Performance}
 */
const perf = getPerformance(FirebaseApp);

export { FirebaseApp, Firestore, Auth, perf }
