import {
	getAuth,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	updateEmail,
	reauthenticateWithCredential,
	updatePassword,
	EmailAuthProvider,
	onAuthStateChanged,
	signOut
} from 'firebase/auth'
import { loggedInUser } from '../../store/stores'
import { Auth } from '../firebase/firebase'
import type { AccountModel } from '../../models/account.model'
import { fireMsg, userMsg } from '../../utils/userMsg.service'
import { accountService } from './account.service'
import { paddleService } from '../APIs/paddle.service'
import { userService } from './user.service'
import { user as currUser } from '../../store/stores'

/** Session keys for various services */
export let sessionKeys: { algoliaKey: string; helpScoutSignature: string; signedNoltToken: string }

/** Interface for login credentials */
interface LoginCredentials {
	email: string
	password: string
}

/** Interface for signup credentials, extending login credentials */
interface SignupCredentials extends LoginCredentials {
	accountName: string
	firstName: string
	lastName: string
}

/** Auth service object containing various authentication methods */
export const authService = {
	login,
	signup,
	sendEmailVerify,
	changeEmail,
	changePassword,
	resetPassword,
	logout
}

/** Firebase Auth instance */
const auth = getAuth()

/**
 * Logs in a user with the provided credentials.
 * @param {LoginCredentials} credentials - The login credentials.
 * @returns {Promise<void>}
 */
async function login(credentials: LoginCredentials): Promise<void> {
	const { email, password } = credentials
	try {
		await signInWithEmailAndPassword(Auth, email, password)
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Login error:', error)
		}
	}
}

/**
 * Signs up a new user with the provided credentials and user ID.
 * @param {SignupCredentials} credentials - The signup credentials.
 * @param {string} userId - The user ID.
 * @returns {Promise<AccountModel>}
 */
async function signup(credentials: SignupCredentials, userId: string): Promise<AccountModel> {
	const { accountName } = credentials
	for (const field in credentials) {
		credentials[field as keyof SignupCredentials] =
			credentials[field as keyof SignupCredentials].trim()
	}
	// Update paddle with new user email for paddle retain
	paddleService.updatePaddle(credentials.email)

	try {
		return await accountService.create({ accountName: accountName, userId: userId })
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Signup error:', error)
		}
		throw error
	}
}

/**
 * Sends an email verification to the current user.
 * @returns {Promise<void>}
 */
async function sendEmailVerify(): Promise<void> {
	const auth = getAuth()
	if (auth.currentUser && !auth.currentUser.emailVerified) {
		try {
			await sendEmailVerification(auth.currentUser)
		} catch (error: unknown) {
			if (error instanceof Error) {
				fireMsg({ type: 'failure', msg: error.message })
				throw new Error('Email verification error:', error)
			}
		}
	}
}

/**
 * Changes the email of the current user after re-authenticating.
 * @param {string} currentPassword - The current password of the user.
 * @param {string} newEmail - The new email to set.
 * @returns {Promise<void>}
 */
async function changeEmail(currentPassword: string, newEmail: string): Promise<void> {
	const auth = getAuth()
	const user = auth.currentUser
	if (!user || !user.email) {
		fireMsg({ type: 'failure', msg: 'No authenticated user found or user email is missing'})
		throw new Error('No authenticated user found or user email is missing')
	}
	try {
		const credential = EmailAuthProvider.credential(user.email, currentPassword)
		const { user: authenticatedUser } = await reauthenticateWithCredential(user, credential)

		if (authenticatedUser) {
			try {
				await updateEmail(user, newEmail)
				await sendEmailVerification(user)
				userMsg.successMsg('Email verification sent, please check your inbox')

				// Update paddle with new user email for paddle retain
				if (user.email) {
					paddleService.updatePaddle(user.email)
				}

				await reauthenticateWithCredential(
					user,
					EmailAuthProvider.credential(newEmail, currentPassword)
				)
				if (user.emailVerified) userMsg.successMsg('Email changed successfully')
			} catch (error: unknown) {
				fireMsg({ type: 'failure', msg: 'Unable to send email verification'})
				throw new Error('Unable to send email verification')
			}
		}
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Email verification error:', error)
		}
		throw error
	}
}

/**
 * Changes the password of the current user after re-authenticating.
 * @param {string} currentPassword - The current password of the user.
 * @param {string} newPassword - The new password to set.
 * @returns {Promise<void>}
 */
async function changePassword(currentPassword: string, newPassword: string): Promise<void> {
	const auth = getAuth()
	const user = auth.currentUser
	if (!user || !user.email) {
		throw new Error('No authenticated user found or user email is missing')
	}
	try {
		const credential = EmailAuthProvider.credential(user.email, currentPassword)

		const { user: authenticatedUser } = await reauthenticateWithCredential(user, credential)

		if (authenticatedUser) {
			try {
				await updatePassword(user, newPassword)
				userMsg.successMsg('Password changed successfully')
			} catch (error: unknown) {
				if (error instanceof Error) {
					fireMsg({ type: 'failure', msg: error.message })
					throw new Error('Change password error:', error)
				}
				throw error
			}
		}
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Change password error:', error)
		}
		throw error
	}
}

/**
 * Sends a password reset email to the specified email address.
 * @param {string} email - The email address to send the reset email to.
 * @returns {Promise<void>}
 */
async function resetPassword(email: string): Promise<void> {
	const auth = getAuth()
	try {
		await sendPasswordResetEmail(auth, email)
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
			throw new Error('Reset password error:', error)
		}
		throw error
	}
}

/**
 * Logs out the current user and clears local storage and IndexedDB.
 * @returns {Promise<void>}
 */
async function logout(): Promise<void> {
	try {
		indexedDB.deleteDatabase('firebaseLocalStorageDb')
		indexedDB.deleteDatabase('firebase-heartbeat-database')
		localStorage.removeItem('training')
		localStorage.removeItem('tourShown')
		localStorage.removeItem('teamTourShown')

		await signOut(auth)
		loggedInUser.set(null);

		// Add ed this to fix the error FirebaseError: [code=permission-denied]: Missing or insufficient permissions.
		// Happing due to the Firestore Listeners, need to wrap Firestore in subscribe method to un listen
		window.location.replace((document.location.href = '/login'))
	} catch (error: unknown) {
		if (error instanceof Error) {
			fireMsg({ type: 'failure', msg: error.message })
		}
		throw error
	}
}

/**
 * Listener for authentication state changes.
 * Updates the user store based on the authentication state.
 */
onAuthStateChanged(auth, (user) => {
	if (user) {
		user.emailVerified ? userService.storeUser(user) : currUser.set(user)
	} else {
		//TODO: this has to saty as undefined as changing it messes up the private route guard or redirect
		currUser.set(undefined)
	}
})
