import type { SvelteComponent } from 'svelte'
import Login from './pages/Login.svelte'
import Signup from './pages/Signup.svelte'
import Forgot from './pages/Forgot.svelte'
import Dashboard from './pages/Dashboard.svelte'
import TeamEdit from './cmps/teams/team/edit/TeamEdit.svelte'
import TeamDetails from './cmps/teams/TeamDetails.svelte'
import Teams from './pages/Teams.svelte'
import DogEdit from './cmps/dogs/dog/DogEdit.svelte'
import DogDetails from './cmps/dogs/dog/DogDetails.svelte'
import Dogs from './pages/Dogs.svelte'
import Users from './pages/Users.svelte'
import Calendar from './pages/Calendar.svelte'
import Insights from './pages/Insights.svelte'
import Reports from './pages/Reports.svelte'
import HelpCenter from './pages/HelpCenter.svelte'
import Settings from './pages/Settings.svelte'
import Profile from './pages/Profile.svelte'
import DogAdd from './cmps/dogs/dog/DogAdd.svelte'
import Trainings from './pages/Trainings.svelte'
import TrainingDetails from './cmps/trainings/training/TrainingDetails.svelte'
import TrainingAdd from './cmps/trainings/training/TrainingAdd.svelte'
import UserDetails from './cmps/users/user/UserDetails.svelte'
import ContactUs from './cmps/helpCenter/ContactUs.svelte'
import Roadmap from './cmps/helpCenter/Roadmap.svelte'
import RequestAFeature from './cmps/helpCenter/RequestAFeature.svelte'
import ProfileEdit from './cmps/profile/ProfileEdit.svelte'
import TrainingTypes from './cmps/trainings/training/TrainingTypes.svelte'
import UserEdit from './cmps/users/user/UserEdit.svelte'
import TrainingEdit from './cmps/trainings/training/TrainingEdit.svelte'
import Error from './pages/Error.svelte'
import DogInsights from './cmps/insights/dog/DogInsights.svelte'
import TeamInsights from './cmps/insights/team/TeamInsights.svelte'
import TrainingInsights from './cmps/insights/training/TrainingInsights.svelte'
import UserInsights from './cmps/insights/user/UserInsights.svelte'
import InsightsCompare from './cmps/insights/comparison/InsightsCompare.svelte'
import Pricing from './pages/Checkout.svelte'

/**
 * Represents a route in the application.
 *
 * @template P - The type of the props for the component.
 */
interface Route<P = Record<string, never>> {
	/** The path of the route. */
	path: string
	/** The Svelte component to render for this route. */
	component: new (options: {
		target: HTMLElement
		props?: P
		hydrate?: boolean
		intro?: boolean
	}) => SvelteComponent
	/** Optional child routes. */
	children?: Route[]
}

/** Public routes that do not require authentication. */
const publicRoutes: Route[] = [
	{ path: '/login', component: Login },
	{ path: '/signup', component: Signup },
	{ path: '/forgot', component: Forgot },
]

/** Routes that require authentication. */
const authProtectedRoutes: Route[] = [
	{ path: '/', component: Dashboard },
	{ path: '/error', component: Error },
	{ path: '/teams/edit/:id', component: TeamEdit },
	{ path: '/teams/edit', component: TeamEdit },
	{ path: '/teams/:id', component: TeamDetails },
	{ path: '/teams', component: Teams },
	{ path: '/dogs/edit/:id', component: DogEdit },
	{ path: '/dogs/edit', component: DogEdit },
	{ path: '/dogs/add', component: DogAdd },
	{ path: '/dogs/:id', component: DogDetails },
	{ path: '/dogs', component: Dogs },
	{ path: '/users', component: Users },
	{ path: '/users/:id', component: UserDetails },
	{ path: 'users/:id', component: UserDetails },
	{ path: 'users/edit/:id', component: UserEdit },
	{ path: '/calendar', component: Calendar },
	{ path: '/insights', component: Insights },
	{ path: '/insights/dog/:id', component: DogInsights },
	{ path: '/insights/dog/compare', component: InsightsCompare },
	{ path: '/insights/team/:id', component: TeamInsights },
	{ path: '/insights/team/compare', component: InsightsCompare },
	{ path: '/insights/training/:id', component: TrainingInsights },
	{ path: '/insights/training/compare', component: InsightsCompare },
	{ path: '/insights/user/:id', component: UserInsights },
	{ path: '/insights/user/compare', component: InsightsCompare },
	{ path: '/reports', component: Reports },
	{ path: '/help-center', component: HelpCenter },
	{ path: '/roadmap', component: Roadmap },
	{ path: '/request', component: RequestAFeature },
	{ path: '/contact-us', component: ContactUs },
	{ path: '/settings', component: Settings },
	{ path: '/profile', component: Profile },
	{ path: '/profile/edit', component: ProfileEdit },
	{ path: '/trainings', component: Trainings },
	{ path: '/trainings/:id', component: TrainingDetails },
	{ path: '/trainings/add', component: TrainingAdd },
	{ path: '/trainings/types', component: TrainingTypes },
	{ path: '/trainings/edit/:id', component: TrainingEdit },
	{ path: '/checkout', component: Pricing },
	{ path: '/reports', component: Reports }
]

/* Exported routes object containing both public and authenticated routes. */
export const routes = {
	authProtectedRoutes,
	publicRoutes
}
