<script lang="ts">
import Select from 'svelte-select'
import { _ } from 'svelte-i18n'
import { multiselect } from '../../../store/trainingWidgetsStore'

export let values: {
	items: any[]
	value: string[]
	returnedValue?: { value: string; label: string }[]
	filled: boolean
}
export let data = []
export let id: string = null

export let onChange = () => {
	// if no function is passed leave empty
}

if (!$multiselect[id]) {
	$multiselect[id] = []
}

let items = values.items.map((item) => ({ value: item, label: item }))

$: {
	if (values.returnedValue != null) {
		values.filled = true
	} else values.filled = false
}

if (data.length > 0 && $multiselect[id].length == 0) {
	values.returnedValue = data[0].value.map((item) => item)
}

$: {
	values.value =
		values.returnedValue == null ? [] : values.returnedValue.map((value) => value.value)
	$multiselect[id] = values.value
}
</script>

<Select
	on:change={onChange}
	showChevron={true}
	clearable={false}
	placeholder={$_('please_select')}
	{items}
	multiple={true}
	bind:value={values.returnedValue} />

