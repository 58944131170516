<script lang="ts">
import { fade } from 'svelte/transition'
import { createEventDispatcher } from 'svelte'
import { _ } from 'svelte-i18n'
import NameInitials from '../../common/NameInitials.svelte'
import ResultsDistributionBar from '../ResultsDistributionBar.svelte'
import TrainingTypesDistributionBar from '../TrainingTypesDistributionBar.svelte'
import DistributionBar from '../DistributionBar.svelte'
import TrainingTypeSelector from '../TrainingTypeSelector.svelte'
import CmpLoader from '../../common/loader/CmpLoader.svelte'
import moment from 'moment'
import Icon from 'svelte-icons-pack'
import HiSolidX from 'svelte-icons-pack/hi/HiSolidX'
import AiOutlineBook from 'svelte-icons-pack/ai/AiOutlineBook'
import FaCalendar from 'svelte-icons-pack/fa/FaCalendar'
import AiOutlineClockCircle from 'svelte-icons-pack/ai/AiOutlineClockCircle'
import { onMount, onDestroy } from 'svelte'
import { onSnapshot, type Unsubscribe } from 'firebase/firestore'
import { dogDB } from '../../../services/database/dog.db'
import { dogbaseAiService } from '../../../services/AI/dogbaseAi.service'
import type { DogModel, TrainingTypeOverview } from '../../../models/dog.model'
import 'moment/dist/locale/es' // Import Spanish locale
import 'moment/dist/locale/he' // Import Hebrew locale

export let dogId: string
let dog: DogModel | null = null
let loading = true
let unsubscribe: Unsubscribe

// Provide a default value if overallTrainingTypeDistribution is null
let overallTrainingTypeDistribution = {}
let selectedTrainingTypeOverview: TrainingTypeOverview | undefined

// Round the ratings to the nearest whole number so that we can use them to select the correct smile icon
let roundedTrainerRating: number
let roundedDogRating: number

let dogIcon: string
let trainerIcon: string

const language = localStorage.getItem('language') || 'en' // Set a default language if localStorage value is null
moment.locale(language)

onMount(() => {
	import('flowbite')
})

function initSnapshotListener() {
	if (unsubscribe) unsubscribe()
	unsubscribe = onSnapshot(dogDB.getDogRef(dogId), (doc) => {
		dog = doc.data() as DogModel
		loading = false

		overallTrainingTypeDistribution =
			dog.dogbaseAI?.aiBooklet?.overallTrainingTypeDistribution ?? {}

		roundedTrainerRating = Math.round(dog.dogbaseAI?.aiBooklet?.overallAvgTrainedByRating ?? 0)

		roundedDogRating = Math.round(dog.dogbaseAI?.aiBooklet?.overallAvgDogRating ?? 0)

		dogIcon =
			roundedDogRating >= 0 && roundedDogRating < 3
				? '../../../assets/img/segment_analysis/dog_negative.svg'
				: roundedDogRating == 3
				? '../../../assets/img/segment_analysis/dog_neutral.svg'
				: '../../../assets/img/segment_analysis/dog_positive.svg'

		trainerIcon =
			roundedTrainerRating >= 0 && roundedTrainerRating < 3
				? '../../../assets/img/segment_analysis/handler_negative.svg'
				: roundedTrainerRating == 3
				? '../../../assets/img/segment_analysis/handler_neutral.svg'
				: '../../../assets/img/segment_analysis/handler_positive.svg'

		// If the AI booklet is null, generate initial data
		if (dog.dogbaseAI?.aiBooklet == null) {
			refreshBooklet()
		}
	})
}

async function refreshBooklet() {
	if (!dog) return
	loading = true
	await dogbaseAiService.getDogAIBooklet(dog.id)
	loading = false
}

onMount(async () => {
	initSnapshotListener()
})

onDestroy(() => {
	if (unsubscribe) unsubscribe()
})

const dispatch = createEventDispatcher()

function close() {
	dispatch('close')
}

// Reactive statement to update the results object
$: results = {
	Successful: selectedTrainingTypeOverview?.trainingTypeResultsDistribution?.['Successful'] ?? 0,
	Unsuccessful:
		selectedTrainingTypeOverview?.trainingTypeResultsDistribution?.['Unsuccessful'] ?? 0,
	'Needs-Improvement':
		selectedTrainingTypeOverview?.trainingTypeResultsDistribution?.['Needs-Improvement'] ?? 0
}

$: trainingTypesDist = selectedTrainingTypeOverview?.trainingTypeTypeDistribution
</script>

<div
	class="ai-summary-modal"
	in:fade={{ duration: 250 }}
	out:fade={{ duration: 250 }}>
	<div class="modal-content">
		<div class="header-image">
			<div class="icon-container">
				<Icon
					src={AiOutlineBook}
					size="100px"
					color="white" />
			</div>
			<button
				class="close-button"
				on:click={close}>
				<Icon src={HiSolidX} />
			</button>
		</div>
		<div class="info">
			{#if !loading && dog != null}
				<header class="header">
					<div class="dog-img-container">
						<img
							src={dog?.image}
							alt={dog?.name} />
					</div>
					<h1 class="title">{`${dog.name}'s ${$_('ai_booklet')}`}</h1>
					<span class="beta-badge">Beta</span>
				</header>
				<div class="flex-row info-badge-container">
					<span class="info-container handler-wrapper">
						<p1>Handler</p1>
						<div
							class="flex-row"
							style="margin-top: 5px;">
							<div
								class="svg-container"
								style="margin-right: 15px;">
								<NameInitials name={dog.handler?.name ?? 'DogBase User'} />
							</div>
							<h1 style="margin-top: 5px;">{dog.handler?.name}</h1>
						</div>
					</span>
					<span class="info-badge">
						<div class="info-icon">
							<Icon src={AiOutlineClockCircle} />
						</div>
						<p1
							>{Math.floor(dog.stats.totalTrainingTime / 60)}
							{$_('hours_training_time')}</p1>
					</span>
					<span class="info-badge">
						<div class="info-icon">
							<img
								src="./assets/img/total-trainings.svg"
								alt="" />
						</div>
						<p1>{dog.stats.totalTrainingSessions} {$_('training_sessions')}</p1>
					</span>
					<span class="info-badge">
						<div class="info-icon">
							<Icon src={FaCalendar} />
						</div>
						<p1>{dog.stats.totalTrainingSessions} {$_('days_of_training')}</p1>
					</span>
				</div>
				{#if dog.dogbaseAI?.aiBooklet != null}
					<section class="text-container">
						<span class="ml11">
							<span class="text-wrapper">
								<h1
									class="letters"
									id="letters">
									Overview
								</h1>
								<span
									class="summary-text letters"
									id="letters">{dog.dogbaseAI?.aiBooklet?.dogOverviewSummary.replace(/public/gi, '')}</span
								></span>
						</span>
					</section>
					<section class="flex-row distribution-bar-container bottom-margin">
						<span class="flex-column">
							<h1 class="title">{$_('overall_results')}</h1>
							<ResultsDistributionBar
								results={{
									Successful:
										dog.dogbaseAI?.aiBooklet?.overallResultsDistribution?.['Successful'] ?? 0,
									Unsuccessful:
										dog.dogbaseAI?.aiBooklet?.overallResultsDistribution?.['Unsuccessful'] ?? 0,
									'Needs-Improvement':
										dog.dogbaseAI?.aiBooklet?.overallResultsDistribution?.['Needs-Improvement'] ?? 0
								}} />
						</span>
						<span class="flex-column" style="margin-top: 20px;">
							<h1 class="title">{$_('training_types')}</h1>
							<TrainingTypesDistributionBar results={overallTrainingTypeDistribution} />
						</span>
					</section>
					<section class="flex-row training-type-selector">
						<div class="flex-column wide">
							<h1>Training Types</h1>
							<TrainingTypeSelector
								trainingTypeOverviews={dog.dogbaseAI?.aiBooklet?.trainingTypeOverviews}
								bind:selectedTrainingTypeOverview />
						</div>
					</section>
					<section class="flex-row training-type-overview">
						<div class="flex-column">
							<h1>{selectedTrainingTypeOverview?.trainingTypeName.replace('Public', '')}</h1>
							<span class="text-container text-wrapper"
								>{selectedTrainingTypeOverview?.trainingTypeOverview.replace(/public/gi, '')}</span>
						</div>
					</section>
					<section class="flex-row distribution-bar-container bottom-margin">
						<span class="flex-column">
							<h1 class="title">{$_('results_distribution')}</h1>
							<ResultsDistributionBar {results} />
						</span>
						<span class="flex-column">
							<h1 class="title">
								{selectedTrainingTypeOverview?.trainingTypeName.replace('Public', '') +
									' ' +
									$_('training_types').replace('Public', '')}
							</h1>
							<DistributionBar results={trainingTypesDist} />
						</span>
					</section>
				{:else}
					<div class="flex-row">
						<div class="error-icon">
							<img
								src="../../../assets/exclamation-solid.svg"
								alt="" />
						</div>
						<span
							class="summary-text letters"
							id="letters">Must have at least 10 trainings to generate AI booklet</span>
					</div>
				{/if}
				<div class="button-container">
					<button
						class="btn theme-btn btn-small"
						disabled>
						{$_('share')}</button>
					<button
						class="btn theme-btn btn-small"
						disabled>
						{$_('generate_pdf')}</button>
					<button
						class="btn theme-btn btn-small"
						on:click={refreshBooklet}
						disabled={dog.dogbaseAI?.aiBooklet == null}>
						{$_('refresh_booklet')}
					</button>
				</div>
				<section class="flex-row sentiment-ratings">
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={dogIcon}
								alt="emoji icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">{$_('avg_dog_rating')}</h1>
							<h2 class="value">
								{dog.dogbaseAI?.aiBooklet?.overallAvgDogRating.toFixed(1) ?? 0} out of 5
							</h2>
						</span>
					</div>
					<div class="flex-row sentiment-rating-container">
						<div class="svg-container">
							<img
								width="48px"
								height="48px"
								src={trainerIcon}
								alt="emoji icon" />
						</div>
						<span class="flex-column">
							<h1 class="title">{$_('avg_trainer_rating')}</h1>
							<h2 class="value">
								{dog.dogbaseAI?.aiBooklet?.overallAvgTrainedByRating.toFixed(1) ?? 0} out of 5
							</h2>
						</span>
					</div>
				</section>
			{:else}
				<CmpLoader />
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
.text-container {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;

	.ml11 {
		.text-wrapper {
			position: relative;
			display: inline-block;
			padding-top: 0.1em;
			padding-right: 0.05em;
			padding-bottom: 0.15em;
		}
	}
}

.ai-summary-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(
		--gradient-2,
		linear-gradient(47deg, rgba(38, 74, 89, 0.75) 0%, rgba(45, 115, 146, 0.75) 100%)
	);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;

	.wide {
		width: 100%;
	}

	.modal-content {
		background: white; /* Semi-transparent grey */
		max-width: 950px;
		max-height: 95%;
		border-radius: 8px;

		.header-image {
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 150px;
			background: #ffa470;
			background-size: cover;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;

			.icon-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 125px;
				height: 125px;
				//border-radius: 50%; /* Make it a circle */
				overflow: hidden; /* Ensure the image does not overflow the container */
				//background-color: white; /* Optional: Add a background color to see the white icon better */
				//border: 4px solid #274655; /* Add a thin ring around the container */
			}

			.close-button {
				position: absolute;
				right: 10px;
				top: 10px;
				background: none;
				border: none;
				font-size: 1.5em;
				cursor: pointer;
				&:hover {
					filter: brightness(1.2);
				}
			}
		}

		.info {
			overflow-y: auto;

			.error-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px; /* Adjust as needed */
				height: 50px; /* Adjust as needed */
			}

			.error-icon img {
				width: 50px;
				height: 50px;
			}

			.button-container {
				display: flex;
				justify-content: flex-end; /* Aligns the buttons to the right */
				gap: 10px; /* Space between buttons */
				margin-right: 20px;
				margin-top: 40px;
			}

			.btn-small {
				padding: 5px 10px; /* Adjust padding */
				font-size: 12px; /* Adjust font size */
			}

			.info-badge-container {
				gap: 1.8rem;
				flex-wrap: wrap;
				.info-badge {
					margin-top: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 30px;
					font-size: 100%;

					.info-icon {
						margin-right: 5px;
					}
				}
			}

			padding: 1.5em;

			> * {
				/* Selects all direct children of .info */
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.header {
				display: flex;
				align-items: center;
				background: white; /* Solid background */
				color: var(--clr-txt-dark-primary);
				flex-wrap: wrap;
				h1 {
					font-size: 25px;
				}

				.beta-badge {
					display: inline-block;
					margin-left: 10px;
					margin-right: 10px;
					padding: 4px 12px;
					font-size: 1rem;
					border: 1px solid rgba(0, 0, 0, 0.1);
					border-radius: 4px;
				}
			}

			.handler-wrapper {
				align-items: start;
				margin-bottom: 30px;

				.info-container {
					display: flex;
					flex-direction: column;
					margin-right: 40px;
					font-size: 75%;

					.flex-row {
						align-items: center;

						.svg-container {
							width: 30px;
							height: 30px;
						}
					}
				}
			}

			.distribution-bar-container {
				margin-right: 48px;
				margin-top: 25px;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				span {
					.title {
						font-size: 14px;
						font-family: Nunito;
						font-weight: bold;
						text-transform: capitalize;
						margin-bottom: 5px;
					}

					.value {
						font-size: 18px;
						font-weight: 500;
						white-space: nowrap;
					}
				}
			}

			.training-type-selector {
				margin-top: 50px;
			}

			.training-type-overview {
				margin-top: 30px;
				margin-bottom: 30px;
			}

			.sentiment-ratings {
				margin-top: 30px;
				padding-top: 15px;
				border-top: 1px solid #000; /* Change color as needed */
				justify-content: space-between;
				flex-wrap: wrap;
				overflow-y: auto;

				.sentiment-rating-container {
					margin-right: 48px;
					margin-top: 14px;
					align-items: center;
					overflow-y: auto;

					.svg-container {
						width: 48px;
						height: 48px;
						margin-right: 0.5rem;
					}

					.title {
						font-size: 14px;
						font-family: Nunito;
						font-weight: normal;
						text-transform: capitalize;
					}

					.value {
						font-size: 18px;
						font-weight: 500;
						white-space: nowrap;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 750px) {
	.header-image {
		display: none;
	}

	.bottom-margin {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 1100px) {
	.modal-content {
		margin: 2.5rem;
		max-height: 95%;
	}
}

@media screen and (max-width: 700px) {
	.button-container {
		margin-left: 0 !important;
		margin-top: 10px;
	}
}

@media screen and (max-width: 600px) {
	.handler-wrapper {
		flex-wrap: wrap;
	}

	.info-container {
		margin-top: 0.5rem;
	}
	h1 {
		font-size: 12px;
	}

	.text-container {
		max-height: 150px;

		.text-wrapper {
			font-size: 0.9rem;
		}
	}

	.distribution-bar-container {
		span {
			.value {
				font-size: 14px !important;
				font-weight: 500;
				white-space: nowrap;
			}
		}
	}
}
</style>
